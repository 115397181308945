import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CandidateHub from "../../assets/icons/CandidateHub";
import CommonButton from "../../component/Button";
import SnackBar from "../../component/SnackBar";
import { CommonGrid2 } from "../../modules/common-components/StyledComponents";
import DialogCandidateHub from "../../modules/jobs/jobs-candidate/components/DialogCandidateHub";
import {
  JobJobFormikData,
  JobJobSubmit,
  JobResumeDuplicate,
} from "../../redux/action/Job";
import { ResumeIntelligenceSave } from "../../redux/action/ResumeIntelligence";
import Sidebar from "./Components/Sidebar";

function PortalLayout() {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispactch = useDispatch();
  const [loading, setLoading] = useState({
    open: false,
    prevData: "",
    disabled: false,
    leaveLoading: false,
    saveLoading: false,
    duplicateLoading: false,
    replacedLoading: false,
    openDuplicate: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [saveEditData, setSaveEditData] = React.useState({
    open: false,
    prevData: "",
    leaveLoading: false,
    disabled: false,
    saveLoading: false,
  });

  const JobJobEditData = useSelector((state) => state.jobs.jobJobEditData);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("credential"));
    if (data === null) {
      navigate("auth/login");
    }
  }, []);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!lgUp) setOpen(false);
  }, [lgUp]);

  const handleNavigation = (path, condition) => {
    if (
      location.pathname + location.search ===
      "/reusme-intelligence/create-candidate-resume?resume=parse"
    ) {
      setLoading((prev) => ({
        ...prev,
        open: !resumeIntelligence?.data?.is_duplicate_present,
        openDuplicate: resumeIntelligence?.data?.is_duplicate_present,
        prevData: path,
      }));
    } else if (
      location.pathname.includes("obs/jobs-view") &&
      JSON.stringify(JobJobEditData.formikData) !==
        JSON.stringify(JobJobEditData.jobData)
    ) {
      setSaveEditData((prev) => ({
        ...prev,
        open: true,
        prevData: path,
      }));
    } else if (path) {
      if (condition === "logout") {
        localStorage.clear();
      }
      navigate(path);
    }
  };

  const handleSaveCandidate = () => {
    setLoading({
      open: false,
      prevData: "",
      disabled: false,
      leaveLoading: false,
      saveLoading: false,
      duplicateLoading: false,
      replacedLoading: false,
      openDuplicate: false,
    });
  };

  const resumeIntelligence = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceUpload
  );

  // useEffect(() => {
  //   console.log(
  //     resumeIntelligence,
  //     "resumeIntelligenceUploadresumeIntelligenceUpload"
  //   );
  //   toast.info(
  //     <div style={{ display: "flex", alignItems: "center" }}>
  //       <img
  //         src="https://via.placeholder.com/40"
  //         alt="icon"
  //         style={{ borderRadius: "50%", marginRight: "10px" }}
  //       />
  //       <div>
  //         <strong>Custom Title</strong>
  //         <p style={{ margin: 0 }}>This is a custom toast message!</p>
  //       </div>
  //     </div>,
  //     {
  //       position: "bottom-left",
  //       autoClose: 5000,
  //       closeOnClick: true,
  //       hideProgressBar: false,
  //       progress: undefined,
  //     }
  //   );
  // }, [resumeIntelligence]);

  const handleLeave = () => {
    setLoading((prev) => ({ ...prev, disabled: true, leaveLoading: true }));
    setTimeout(() => {
      setLoading((prev) => ({
        ...prev,
        disabled: false,
        leaveLoading: false,
        open: false,
      }));
      navigate(loading.prevData);
    }, 1000);
  };

  const handleSave = async () => {
    setLoading((prev) => ({ ...prev, disabled: true, saveLoading: true }));
    try {
      let params = {
        resume_id: resumeIntelligence.data.id,
      };
      const data = await dispactch(ResumeIntelligenceSave(params));
      setSnackbarState({
        open: true,
        severity: "success",
        message: data.message,
      });
      setLoading((prev) => ({
        ...prev,
        disabled: false,
        saveLoading: false,
        open: false,
      }));
      navigate(loading.prevData);
    } catch (error) {
      setLoading((prev) => ({ ...prev, disabled: false, saveLoading: false }));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
    }
  };

  const handleJobLeave = async () => {
    setSaveEditData((prev) => ({
      ...prev,
      disabled: true,
      leaveLoading: true,
    }));
    await dispatch(
      JobJobFormikData(JobJobEditData.jobData, JobJobEditData.jobData)
    );
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        leaveLoading: false,
        open: false,
      }));
      navigate(saveEditData.prevData);
    } catch (error) {}
  };

  const handleJobClose = () => {
    setSaveEditData((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleJobSave = async () => {
    setSaveEditData((prev) => ({
      ...prev,
      disabled: true,
      saveLoading: true,
    }));

    const values = JobJobEditData.formikData;
    const result = (valueData) => {
      if (!valueData) return [];
      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const otherData = processedData.filter(
        (item) => item.name !== "extracted"
      );

      if (!extracted) {
        return [{ name: "extracted", content: [] }, ...otherData];
      }

      return [extracted, ...otherData];
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: values.location_targets.map((item) => item.content),
      high_priority: values.skillData.high_priority,
      low_priority: values.skillData.low_priority,
      medium_priority: values.skillData.medium_priority,
      recommended: values.skillData.recommended,
    };

    try {
      dispatch(JobJobFormikData(values, values));
      const jobEdit = await JobJobSubmit(values.id, data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: jobEdit.message,
      });
      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        saveLoading: false,
        open: false,
      }));
      navigate(saveEditData.prevData);
    } catch (error) {
      dispatch(JobJobFormikData(JobJobEditData.jobData, values));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        saveLoading: false,
      }));
    }
  };
  const Footer = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        color="#344054"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        border={"#D0D5DD"}
        onClick={loading.open ? handleLeave : handleJobLeave}
        disabled={loading.open ? loading.disabled : saveEditData.disabled}
        loading={
          loading.open ? loading.leaveLoading : saveEditData.leaveLoading
        }
      />
      <CommonButton
        value={"Save"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={loading.open ? loading.disabled : saveEditData.disabled}
        loading={loading.open ? loading.saveLoading : saveEditData.saveLoading}
        onClick={loading.open ? handleSave : handleJobSave}
      />
    </CommonGrid2>
  );

  const FooterViewResume = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Duplicate"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={loading.disabled}
        loading={loading.duplicateLoading}
        onClick={() => handleDuplicate("duplicate")}
      />
      <CommonButton
        value={"Replace Existing"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={loading.disabled}
        loading={loading.replacedLoading}
        onClick={() => handleDuplicate("replaced")}
      />
    </CommonGrid2>
  );

  const handleDuplicate = async (action) => {
    let params = {
      action: action,
      resume_id: resumeIntelligence?.data.id,
    };
    setLoading((prev) => ({
      ...prev,
      disabled: true,
      duplicateLoading: action === "duplicate" ? true : false,
      replacedLoading: action === "replaced" ? true : false,
    }));
    try {
      const data = await JobResumeDuplicate(params);

      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      setLoading((prev) => ({
        ...prev,
        openDuplicate: false,
        disabled: false,
        duplicateLoading: false,
        replacedLoading: false,
      }));
      navigate(loading.prevData);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setLoading((prev) => ({
        ...prev,
        disabled: false,
        duplicateLoading: false,
        replacedLoading: false,
      }));
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log(resumeIntelligence, "resumeIntelligenceresumeIntelligence");

  return (
    <Box display={"flex"} minHeight={"100vh"} overflow={"hidden"}>
      <Box
        flexShrink={0}
        width={!lgUp ? theme.spacing(8) : open ? 312 : theme.spacing(8)}
        borderRight={"1px solid #EAECF0"}
        height={"100vh"}
        position={"sticky"}
        top={0}
        sx={{
          transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
        }}
      >
        <Box
          padding={theme.spacing(1)}
          position={"absolute"}
          top={"3.2rem"}
          zIndex={"9999"}
          right={"-27px"}
          sx={{
            transition: theme.transitions.create(["right"], {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
            "& button": {
              borderRadius: "10px",
              padding: "5px",
            },
          }}
        >
          <IconButton
            onClick={toggleDrawer}
            sx={{
              background: "#7F56D9",
              color: "#fff",
              "&:hover": { background: "#7F56D9" },
            }}
          >
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        <Sidebar
          open={open}
          setOpen={setOpen}
          handleNavigation={handleNavigation}
        />
      </Box>

      <Box
        flex={1}
        height={"100vh"}
        padding={"32px"}
        sx={{
          overflowY: "auto",
          transition: theme.transitions.create(["max-width", "margin"], {
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <Outlet />
      </Box>

      <DialogCandidateHub
        open={loading.open}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        title="Save to Candidate Hub?"
        handleClose={handleSaveCandidate}
        footer={<Footer />}
        content={
          "You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to proceed?"
        }
      />
      <DialogCandidateHub
        open={saveEditData.open}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        title="Save to JEM"
        handleClose={handleJobClose}
        footer={<Footer />}
        content={
          "You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to proceed?"
        }
      />

      <DialogCandidateHub
        open={loading.openDuplicate}
        handleClose={handleSaveCandidate}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        footer={<FooterViewResume />}
        title="Candidate Already Exists"
        content={
          <>
            You are about to save a candidate under the name{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeIntelligence?.data?.personal_details?.candidate_name}
            </strong>{" "}
            and the role{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeIntelligence?.data?.personal_details?.inferred_title}
            </strong>{" "}
            in the Candidate Hub.
          </>
        }
      />
      {/* <div>
        <NotificationRelative>
          <IconButton onClick={handleClick}>
            <NotificationsNoneOutlinedIcon />
          </IconButton>
        </NotificationRelative>
        <ArrowMenu
        // anchorEl={anchorEl}
        // open={openMenu}
        // onClose={handleClose}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        // transformOrigin={{
        //   vertical: "bottom",
        //   horizontal: "right",
        // }}
        >
          {resumeIntelligence.files?.map((file, index) => {
            console.log(file, "s,fnsfngnf");
            return (
              <FileContainer
                key={index}
                elevation={1}
                gap={"1rem"}
                padding="10px"
                alignItems="center"
                className="sfdjbsfjn"
              >
                <Box display={"flex"} alignItems={"flex-start"} width={"25px"}>
                  {getFileIcon(file)}
                </Box>

                <Box width={"100%"}>
                  <Typography
                    color="#344054"
                    fontSize={"12px"}
                    fontWeight={700}
                    style={{ wordBreak: "break-word" }}
                  >
                    {file.name}
                  </Typography>

                  <Typography
                    color="#475467"
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    {file.size}
                  </Typography>
                  {resumeIntelligence?.jsonResponse?.data?.id && (
                    <Typography
                      color="green"
                      fontSize={"12px"}
                      fontWeight={700}
                    >
                      {resumeIntelligence.jsonResponse?.message}
                    </Typography>
                  )}
                  {file.progress >= 0 && (
                    <CommonTippy
                      content={file.message}
                      visible={file.visible}
                      interactive={true}
                      trigger="click"
                      hideOnClick={false}
                      zIndex={2}
                      color="red"
                    >
                      <StyledLinearContainer>
                        <CommonLinearProgress
                          variant={
                            file.progress && !file.visible
                              ? "buffer"
                              : "determinate"
                          }
                          value={file.progress}
                          valueBuffer={file.buffer}
                          height={"8px"}
                          borderRadius={"2px"}
                          backgroundColor={"#E0E0E0"}
                          barbackgroundColor={file.visible ? "red" : "#7F56D9"}
                          dashedmarginTop={"2px"}
                          dashedbackgroundImage={
                            file.visible
                              ? "radial-gradient(rgb(255 46 0) 0%, rgb(255 0 0) 16%, #00000000 42%)"
                              : "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)"
                          }
                        />

                        <Typography
                          color="#344054"
                          fontSize={"12px"}
                          fontWeight={500}
                        >
                          {file.progress}%
                        </Typography>
                      </StyledLinearContainer>
                    </CommonTippy>
                  )}
                </Box>
              </FileContainer>
            );
          })}
        </ArrowMenu>
      </div> */}
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </Box>
  );
}

export default PortalLayout;
