import { URL } from "../../api/Api";
import { JOBS } from "../Action";
import { catchError, DELETE, GET, POST, PUT } from "../axios/Axios";

export const JobSummary = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/job-summary/${job_id}`, {});
      dispatch({ type: JOBS.jobSummary, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const JobJobEdit = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/jobdata/edit/${job_id}`, {});
      dispatch({ type: JOBS.jobJobEdit, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const JobJobSkillEdit = async (id, key, classification, payload) => {
  try {
    const getClassification = classification
      ? `&classification=${classification}`
      : "";
    const response = await POST(
      `${URL}/intake/skill/update?id=${id}&key=${key}${getClassification}`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    console.log(error, "sjfnbsjkfsbfn");
    throw catchError(error);
  }
};

export const JobJobShare = async (payload, type) => {
  try {
    const response = await POST(
      type === "summary"
        ? `${URL}/intake/share/job`
        : `${URL}/matchengine/share/candidateprofile`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobSubmit = async (id, payload, params) => {
  try {
    const response = await PUT(
      `${URL}/intake/jobdata/edit/${id}`,
      payload,
      params ? params : {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobDelete = async (id) => {
  try {
    const response = await DELETE(
      `${URL}/matchengine/match/resume/jd/${id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobLock = async (payload) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/matchresume/order`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobReScore = async (id, payload) => {
  try {
    const response = await fetch(
      `${URL}/intake/job/rescore/${id}?score=${payload.score}`,
      {
        method: "PUT",
        body: "",
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobFormikData = (jobValue, values) => {
  return {
    type: JOBS.jobJobEditData,
    jobData: jobValue,
    formikData: values,
  };
};

export const JobResumeDuplicate = async (params) => {
  try {
    const response = await POST(`${URL}/resume/save/`, {}, params);
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

// Create-Job
export const JobIndustryList = async () => {
  try {
    const response = await GET(`${URL}/intake/industries/list/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobIndustrySubmit = async (payload) => {
  try {
    const response = await POST(`${URL}/intake/jobdata/title`, payload, {});
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobIndustryScoreSubmit = async (param) => {
  try {
    const response = await POST(`${URL}/intake/jobdata/title/score`, {}, param);
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobClassificationList = async (param) => {
  try {
    const response = await GET(`${URL}/intake/classification/list/`, param);
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

// export const JobJDSubmit = async (payload) => {
//   try {
//     const response = await POST(`${URL}/intake/jobdata/jd`, payload);
//     return response.data;
//   } catch (error) {
//     throw catchError(error);
//   }
// };

export const JobJDSubmit = async (payload) => {
  try {
    const response = await fetch(`${URL}/intake/jobdata/jd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // const data = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};
