import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { TabContext } from "@mui/lab";
import { Grid2, Tab, TableCell, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { GetJobList } from "../../../api/Api";
import SnackBar from "../../../component/SnackBar";
import {
  JobClassificationList,
  JobIndustryList,
  JobIndustrySubmit,
  JobJDSubmit,
} from "../../../redux/action/Job";
import { UploadJob } from "../../../redux/action/QuickMatch";
import { GET } from "../../../redux/axios/Axios";
import {
  CreateTabList,
  DotColor,
  StatusChange,
  TabPanelView,
} from "../../common-components/StyledComponents";
import InputJobData from "./components/InputJobData";
import ReviewJobPost from "./components/ReviewJobPost";
import SelectCreationModel from "./select-creation-model";

const CreateJob = () => {
  const navigate = useNavigate();
  const [inputJdView, setInputJdView] = useState("");
  const [enrichFile, setEnrichFile] = useState([]);
  const [uploadJobData, setUploadJobData] = useState({});
  const [inputJobDrag, setInputJobDrag] = useState(false);
  const [createJobList, setCreateJobList] = useState({
    data: [],
    currentPage: 1,
    totalPage: "",
    loading: false,
  });
  const limit = 10;

  const [tabData, setTabData] = useState([
    {
      name: "Select Creation Model",
      disabled: false,
    },
    {
      name: "Input Job Data",
      disabled: true,
    },
    {
      name: "Review Job Post",
      disabled: true,
    },
  ]);
  const [value, setValue] = React.useState("0");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const [list, setList] = useState({
    industry: [],
    classification: [],
    disabled: false,
    loading: false,
    buttonLoading: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const selectCreateData = [
    {
      name: "Enrich existing Job Post",
      description: "Description",
      key: 1,
      disabled: false,
    },
    {
      name: "Create a Job Post using Sample Job Description",
      description: "Description",
      key: 2,
      disabled: false,
      apiCall: true,
    },
    {
      name: "Auto-Generate Job Post using a Title",
      description: "Description",
      key: 3,
      disabled: false,
      apiCall: true,
    },
    {
      name: "Create Job Post using Resume",
      description: "Description",
      key: 4,
      disabled: true,
    },
    {
      name: "Create Job Post Manually",
      description: "Description",
      key: 5,
      disabled: true,
    },
  ];

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleContinue = async (data) => {
    console.log(data, "sfkjsfkk");
    setEnrichFile([]);
    setValue(data.key === 5 ? "2" : "1");
    // setTabData((prev) =>
    //   prev.map((tab, index) => {
    //     let isDisabled = true;
    //     if (data.key === 1 && index === 0) {
    //       isDisabled = false;
    //     } else if (data.key === 5) {
    //       isDisabled = true;
    //     }
    //     return { ...tab, disabled: isDisabled };
    //   })
    // );

    setTabData((prev) =>
      prev.map((tab, index) => ({
        ...tab,
        disabled:
          data.key !== 5 && index === 0 ? false : data.key === 5 ? true : true,
      }))
    );

    navigate(`/jobs/create-jobs?key=${data.key}`);
    setInputJdView(data.key);
    if (data?.apiCall) {
      try {
        setList((prev) => ({
          ...prev,
          industry: [],
          disabled: true,
          loading: true,
          classification: [],
        }));
        let classification = [];
        if (data.key === 3 || data.key === 2) {
          const listData = await JobIndustryList();

          if (data.key === 2) {
            classification = await JobClassificationList();
          }
          setList((prev) => ({
            ...prev,
            industry: listData,
            classification: classification,
            disabled: false,
            loading: false,
          }));
        }
      } catch (error) {
        setSnackbarState({
          open: true,
          message: error.message,
          severity: "error",
        });
        setList({
          buttonLoading: false,
          industry: [],
          disabled: false,
          loading: false,
          classification: [],
        });
      }
    }
  };

  const handleInputJobDragOver = (event) => {
    event.preventDefault();
    setInputJobDrag(true);
  };

  const handleInputJobDragLeave = () => {
    setInputJobDrag(false);
  };

  const handleInputJobDrop = (event) => {
    event.preventDefault();
    setInputJobDrag(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(
      droppedFiles,
      allowedTypes,
      setEnrichFile,
      enrichFile
    );
  };

  const uploadFile = async (getName, setCandidateFiles, payload) => {
    setTabData((prev) =>
      prev.map((tab, index) => ({
        ...tab,
        disabled: true,
      }))
    );
    if (getName === "NextUpload") {
      setButtonLoading(true);
      setCandidateFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          delete: false,
        }))
      );
    }

    const formData = new FormData();
    if (getName === "NextUpload") {
      enrichFile.forEach((fileObj, index) => {
        formData.append("files", fileObj.file);
      });
    }

    try {
      const response =
        getName === "NextUpload"
          ? await UploadJob(formData, getName, "Draft")
          : await JobJDSubmit(payload);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse, getName, setCandidateFiles);
          } catch (e) {
            setTabData((prev) =>
              prev.map((tab, index) => ({
                ...tab,
                disabled: index === 0 ? false : true,
              }))
            );
            if (getName === "NextUpload") {
              setCandidateFiles((prevFiles) =>
                prevFiles.map((f) => ({
                  ...f,
                  visible: true,
                  delete: true,
                  message: jsonResponse.message,
                }))
              );
            }

            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse, getName, setCandidateFiles);
        } catch (e) {
          setTabData((prev) =>
            prev.map((tab, index) => ({
              ...tab,
              disabled: index === 0 ? false : true,
            }))
          );
          if (getName === "NextUpload") {
            setCandidateFiles((prevFiles) =>
              prevFiles.map((f) => ({
                ...f,
                visible: true,
                delete: true,
                message: jsonResponse.message,
              }))
            );
          } else if (getName === "JDNext") {
            setList((prev) => ({
              ...prev,
              buttonLoading: false,
              disabled: false,
              loading: false,
              visible: true,
              message: jsonResponse.message,
            }));
          }

          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
        }
      }
      if (getName === "NextUpload") {
        setButtonLoading(false);
      }
    } catch (error) {
      setTabData((prev) =>
        prev.map((tab, index) => ({
          ...tab,
          disabled: index === 0 ? false : true,
        }))
      );
      if (getName === "NextUpload") {
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: "Error during the request",
          }))
        );
      } else if (getName === "JDNext") {
        setList((prev) => ({
          ...prev,
          buttonLoading: false,
          disabled: false,
          loading: false,
          visible: true,
          message: "Error during the request",
        }));
      }

      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });

      if (getName === "NextUpload") {
        setButtonLoading(false);
      }
    }
  };

  const processJsonResponse = (jsonResponse, getName, setCandidateFiles) => {
    const { success, data, message } = jsonResponse;
    console.log(jsonResponse, "sfksfnskfnskfnsfjnjns");
    // setUploadJobData(jsonResponse);
    if (success && data?.percent !== undefined) {
      if (getName === "NextUpload") {
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            progress: data.percent,
            buffer: data.percent,
            visible: false,
            delete: false,
          }))
        );
      }
      if (getName === "JDNext") {
        setList((prevFiles) => ({
          ...prevFiles,
          progress: data.percent,
          buffer: data.percent,
          visible: false,
        }));
      }
    } else if (data?.id) {
      setUploadJobData(data?.id);

      setSnackbarState({
        open: true,
        message: message,
        severity: "success",
      });

      setTabData((prev) =>
        prev.map((tab, index) => ({
          ...tab,
          disabled: true,
        }))
      );
      setValue("2");
    } else if (!success) {
      setTabData((prev) =>
        prev.map((tab, index) => ({
          ...tab,
          disabled: index === 0 ? false : true,
        }))
      );
      if (getName === "NextUpload") {
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: message,
          }))
        );
      } else if (getName === "JDNext") {
        setList((prev) => ({
          ...prev,
          buttonLoading: false,
          disabled: false,
          loading: false,
          visible: true,
          message: message,
        }));
      }
    }
  };

  const handleInputJobDeleteFile = (index) => {
    setEnrichFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const commonFileValidationCheck = (
    commonFiles,
    allowedTypes,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = [];
    const invalidFiles = [];

    commonFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push({
          file,
          name: file.name,
          size: `${(file.size / 1024).toFixed(1)} KB`,
          type: file.type,
          progress: 0,
          buffer: 0,
          visible: false,
          delete: true,
          message: "",
        });
      } else {
        invalidFiles.push(file.name);
      }
    });

    const mergedFiles = [
      ...validFiles.filter(
        (newFile) =>
          !existingFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      ),
    ];
    setCommonFiles(mergedFiles);

    if (invalidFiles.length > 0) {
      setSnackbarState({
        open: true,
        message: `Unsupported file types: ${invalidFiles.join(", ")}`,
        severity: "error",
      });
    }
  };

  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(
      selectedFiles,
      allowedTypes,
      setEnrichFile,
      enrichFile
    );
  };

  const handleButtonCommon = (data) => {
    if (data === 1) {
      uploadFile("NextUpload", setEnrichFile);
    }
  };

  const fetchJobsList = async (limit, offset) => {
    setCreateJobList((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response = await GET(GetJobList(limit, offset, "Draft"));
      const data = response.data;
      setCreateJobList((prev) => ({
        ...prev,
        data: data?.results || [],
        totalPage: data?.total_pages,
        loading: false,
      }));
    } catch (err) {
      setCreateJobList((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchJobsList(limit, (createJobList.currentPage - 1) * limit);
  }, [createJobList.currentPage]);

  const headerData = [
    "Req ID",
    "Title",
    "Status",
    "Hourly Rate",
    "Client",
    "Created",
    "",
  ];
  const sortItem = ["Req ID", "Status", "Hourly Rate", "Created"];

  const handleIconClick = (row) => {
    setUploadJobData(row?.id);
    setTimeout(() => {
      setValue("2");
    }, 0);
  };
  const handleTableBodyData = (row, index) => {
    if (!row) return null;

    return (
      <>
        <TableCell>{row.req_id || "-"}</TableCell>
        <TableCell>{row.job_title || "-"}</TableCell>

        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.status === "Active"
                  ? "green"
                  : row.status === "Hold"
                  ? "orange"
                  : "#667085"
              }
            />
            {row.status || "-"}
          </StatusChange>
        </TableCell>
        <TableCell>{row.pay_rate || "-"}</TableCell>
        <TableCell>{row.client || "-"}</TableCell>
        <TableCell>
          {row.created_at ? new Date(row.created_at).toLocaleDateString() : "-"}
        </TableCell>
        <TableCell>
          <VisibilityOutlinedIcon
            onClick={() => handleIconClick(row)}
            style={{ cursor: "pointer" }}
          />
        </TableCell>
      </>
    );
  };

  const handlePageChange = (page) => {
    setCreateJobList((prev) => ({
      ...prev,
      currentPage: page,
    }));
  };

  const titleInitialValues = {
    title: "",
    industry: "",
    company: "",
  };

  const JDInitialValues = {
    location: "",
    industry: "",
    occupational_classification: "",
    sample_jd_text: "",
  };

  const validationSchemaTitle = Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });

  const validationSchemaJD = Yup.object().shape({
    sample_jd_text: Yup.string().required("Description is required"),
  });

  const handleTitleSubmit = async (values) => {
    setList((prev) => ({
      ...prev,
      loading: false,
      disabled: true,
      buttonLoading: true,
    }));
    setTabData((prev) =>
      prev.map((tab, index) => ({
        ...tab,
        disabled: true,
      }))
    );

    try {
      const data = await JobIndustrySubmit(values);
      setUploadJobData(data?.data?.id);
      setList((prev) => ({
        ...prev,
        disabled: false,
        buttonLoading: false,
        loading: false,
      }));
      setValue("2");
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setList((prev) => ({
        ...prev,
        buttonLoading: false,
        disabled: false,
        loading: false,
      }));
      setTabData((prev) =>
        prev.map((tab, index) => ({
          ...tab,
          disabled: index === 0 ? false : true,
        }))
      );
    }
  };

  const handleJDSubmit = async (values) => {
    setList((prev) => ({
      ...prev,
      loading: false,
      disabled: true,
      buttonLoading: true,
      progress: 0,
      buffer: 0,
    }));
    // setTabData((prev) =>
    //   prev.map((tab, index) => ({
    //     ...tab,
    //     disabled: true,
    //   }))
    // );
    uploadFile("JDNext", setList, values);
    // try {
    //   const data = await JobJDSubmit(values);
    //   setUploadJobData(data?.data?.id);
    //   setList((prev) => ({
    //     ...prev,
    //     disabled: false,
    //     buttonLoading: false,
    //     loading: false,
    //   }));
    //   setValue("2");
    //   setSnackbarState({
    //     open: true,
    //     message: data.message,
    //     severity: "success",
    //   });
    // } catch (error) {
    //   setSnackbarState({
    //     open: true,
    //     message: error.message,
    //     severity: "error",
    //   });
    // setList((prev) => ({
    //   ...prev,
    //   buttonLoading: false,
    //   disabled: false,
    //   loading: false,
    // }));
    // setTabData((prev) =>
    //   prev.map((tab, index) => ({
    //     ...tab,
    //     disabled: index === 0 ? false : true,
    //   }))
    // );
    // }
  };

  return (
    <Grid2 alignItems="center">
      <Typography variant="h2">Create Job</Typography>

      <TabContext value={value}>
        <Grid2 alignItems="center" marginTop={3}>
          <CreateTabList onChange={handleChange} aria-label="create-job">
            {tabData.map((item, index) => (
              <Tab
                label={item.name}
                value={index.toString()}
                disabled={item.disabled}
              />
            ))}
          </CreateTabList>
        </Grid2>
        <TabPanelView value="0" padding="1.5rem 0px 0px">
          <SelectCreationModel
            selectCreateData={selectCreateData}
            headerData={headerData}
            handleContinue={handleContinue}
            handleFileUpload={handleFileUpload}
            createJobList={createJobList}
            handleTableBodyData={handleTableBodyData}
            sortItem={sortItem}
            handlePageChange={handlePageChange}
          />
        </TabPanelView>
        <TabPanelView value="1" padding="1.5rem 0px 0px">
          <InputJobData
            inputJdView={inputJdView}
            handleButtonCommon={handleButtonCommon}
            handleFileUpload={handleFileUpload}
            enrichFile={enrichFile}
            buttonLoading={buttonLoading}
            handleInputJobDeleteFile={handleInputJobDeleteFile}
            handleInputJobDragOver={handleInputJobDragOver}
            handleInputJobDragLeave={handleInputJobDragLeave}
            handleInputJobDrop={handleInputJobDrop}
            inputJobDrag={inputJobDrag}
            list={list}
            titleInitialValues={titleInitialValues}
            validationSchemaTitle={validationSchemaTitle}
            handleTitleSubmit={handleTitleSubmit}
            JDInitialValues={JDInitialValues}
            validationSchemaJD={validationSchemaJD}
            handleJDSubmit={handleJDSubmit}
          />
        </TabPanelView>
        <TabPanelView value="2" padding="1.5rem 0px 0px">
          <ReviewJobPost
            alignItems={"Edit"}
            jobId={uploadJobData}
            inputJdView={inputJdView}
          />
        </TabPanelView>
      </TabContext>

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </Grid2>
  );
};
export default CreateJob;
