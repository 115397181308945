import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { fixedJob } from "../../../../api/Api";
import CommonButton from "../../../../component/Button";
import SnackBar from "../../../../component/SnackBar";
import {
  JobIndustryScoreSubmit,
  JobJobEdit,
  JobJobSubmit,
} from "../../../../redux/action/Job";
import { GET } from "../../../../redux/axios/Axios";
import {
  CommonGrid2,
  CustomAccordion,
} from "../../../common-components/StyledComponents";
import AddNewSkillDialog from "../../job-information/Dialog";
import PrimaryInformation from "../../job-information/primary-information";
import RelevancyTable from "../../job-information/RelevancyTable";
import Result from "../../job-information/result";
import SecondaryInformation from "../../job-information/secondary-information";
import Skills from "../../job-information/skills";
import SkillDeleteDialog from "../../job-information/skills/SkillDeleteDialog";
import AddSeededDialog from "./AddSeededDialog";
import InterviewSettings from "./InterviewSettings";
import SeededPreScreen from "./SeededPreScreen";

const ReviewJobPost = ({ alignItems, jobId, inputJdView }) => {
  const alignment = alignItems;
  const [skillDialog, setSkillDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    skill: "",
    experience_in_yrs: "",
    criticality_score: "",
    title: "Create",
    classificationData: [],
    classification: false,
    classificationDataValue: "",
    key: "",
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const JobJobEditData = useSelector((state) => state.jobs.jobJobEdit);
  const [jobData, setJobData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [open, setOpen] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const criticalityData = [
    { name: "NTH", value: "nice to have" },
    { name: "Desired", value: "preferred" },
    { name: "Required", value: "required" },
  ];
  const tableContainerRef = React.useRef(null);

  const [seededDialog, setSeededDialog] = useState({
    deleteOpen: false,
    loading: false,
    form: "",
    index: "",
    content: "",
    addOpen: false,
  });
  const navigate = useNavigate();

  const value = (value) => (value ? value : "-");

  const jobModifyData = {
    job_title: "",
    company_name: "",
    industry: "",
    available_industry: [],
    payRateValue: {
      max: "",
      min: "",
      minCurrency: "USD",
      maxCurrency: "USD",
    },
    pay_rate: {
      max: "",
      min: "",
      currency: "USD",
    },
    management_level: "",
    available_management_level: [],
    employment: "",
    available_employment_types: [],
    Date_Range: {
      start_date: "",
      end_date: "",
    },
    criticalityHardData: [
      { name: "Familiar", value: "Familiar" },
      { name: "Hands-on", value: "Hands-on" },
      { name: "Expert", value: "Expert" },
    ],
    criticalitySoftData: [
      { name: "Novice", value: "Novice" },
      { name: "Proficient", value: "Proficient" },
      { name: "Expert", value: "Expert" },
    ],
    priorityData: [
      {
        name: "Medium",
        value: "Additional Qualities",
        key: "medium_priority",
      },
      {
        name: "High",
        value: "Complementary Skills",
        key: "low_priority",
      },
      {
        name: "Highest",
        value: "Core Skills",
        key: "high_priority",
      },
    ],
    skillType: [
      { name: "Hard Skill", value: "extracted_hard_skill" },
      { name: "Soft Skill", value: "extracted_soft_skill" },
    ],
    occupational_classification: "",
    available_occupational_classification: [],
    employeeTarget: "",

    autoCompleteValue: "",
    id: "",
    reports_to: "",
    req_id: "",
    bu_department_team: "",
    company_profile: "",
    available_company_profiles: [],
    education: "",
    available_education: "",
    dei: false,
    govt_security_clearance: false,
    languages: [],
    certificates: [],
    certificates_value: "",
    employer_targets: [],
    employer_targets_value: "",
    location: "",
    languages_value: "",
    location_targets: [],
    location_targets_value: "",
    shift: [],
    shift_value: "",
    skillData: {
      low_priority: [],
      high_priority: [],
      recommended: [],
      medium_priority: [],
    },
    score_certificates: false,
    score_education: false,
    score_employer_targets: false,
    score_hard_skills: false,
    score_languages: false,
    score_location_targets: false,
    score_soft_skills: false,
    score_visa: false,
    relevancy_index: [],
    interview_type: [],
    pre_screen_questions: [],
    available_visa_status: [],
    visa_status: "",
  };
  const [jobValue, setJobValue] = useState(jobModifyData);

  useEffect(() => {
    setJobValue(jobModifyData);
    if (inputJdView === 5) {
      fetchJobEdit("");
    } else fetchJobEdit(jobId);
  }, []);

  const handleDynmaicList = (data) => {
    return data?.flatMap((item) =>
      item?.content?.map((contentItem) => ({
        name: item?.name,
        content: contentItem,
      }))
    );
  };
  useEffect(() => {
    if (Object.keys(JobJobEditData).length !== 0) {
      setJobValue({
        ...jobValue,
        job_title: JobJobEditData.job_title,
        company_name: JobJobEditData.company_name,
        industry: JobJobEditData.industry,
        available_industry: JobJobEditData.available_industry,
        payRateValue: {
          ...JobJobEditData.pay_rate,
          minCurrency: JobJobEditData.pay_rate?.currency,
          maxCurrency: JobJobEditData.pay_rate?.currency,
        },
        management_level: JobJobEditData.management_level,
        available_management_level: JobJobEditData.available_management_level,
        employment: JobJobEditData.employment,
        available_employment_types: JobJobEditData.available_employment_types,
        Date_Range: {
          start_date: JobJobEditData.start_date,
          end_date: JobJobEditData.end_date,
        },
        occupational_classification: JobJobEditData.occupational_classification,
        available_occupational_classification:
          JobJobEditData.available_occupational_classification,
        id: JobJobEditData.id,
        reports_to: JobJobEditData.reports_to,
        req_id: JobJobEditData.req_id,
        bu_department_team: JobJobEditData.bu_department_team,
        company_profile: JobJobEditData.company_profile,
        available_company_profiles: JobJobEditData.available_company_profiles,
        education: JobJobEditData.education,
        available_education: JobJobEditData.available_education,
        dei: JobJobEditData.dei,
        govt_security_clearance: JobJobEditData.govt_security_clearance,
        languages: handleDynmaicList(JobJobEditData.languages),
        certificates: handleDynmaicList(JobJobEditData.certificates),
        employer_targets: handleDynmaicList(JobJobEditData.employer_targets),
        location: JobJobEditData.location,
        location_targets: JobJobEditData.location_targets.map((item) => {
          return { content: item, id: "" };
        }),
        shift:
          JobJobEditData.shift.length === 0
            ? []
            : JobJobEditData?.shift?.map((item) => {
                return { content: item, id: "" };
              }),
        pay_rate: {
          ...JobJobEditData.pay_rate,
          max: JobJobEditData.pay_rate.max,
          min: JobJobEditData.pay_rate.min,
          currency: JobJobEditData.pay_rate.currency || "USD",
        },
        skillData: {
          low_priority: JobJobEditData.low_priority,
          high_priority: JobJobEditData.high_priority,
          recommended: JobJobEditData.recommended,
          medium_priority: JobJobEditData.medium_priority,
        },
        score_certificates: JobJobEditData.score_certificates,
        score_education: JobJobEditData.score_education,
        score_employer_targets: JobJobEditData.score_employer_targets,
        score_hard_skills: JobJobEditData.score_hard_skills,
        score_languages: JobJobEditData.score_languages,
        score_location_targets: JobJobEditData.score_location_targets,
        score_soft_skills: JobJobEditData.score_soft_skills,
        score_visa: JobJobEditData.score_visa,
        relevancy_index: JobJobEditData.relevancy_index,
        interview_type: JobJobEditData.interview_type,
        pre_screen_questions: JobJobEditData.pre_screen_questions,
        available_visa_status: JobJobEditData.available_visa_status,
        visa_status: JobJobEditData.visa_status,
      });
    }
  }, [JobJobEditData]);

  const validationSchema = Yup.object().shape({
    job_title: Yup.string().required("Title is required"),
    company_name: Yup.string().required("Company is required"),
    industry: Yup.string().required("Industry is required"),
    management_level: Yup.string().required("Seniority level is required"),
    employment: Yup.string().required("Employment type is required"),
    location: Yup.string().required("Location is required"),

    skillData: Yup.object({
      high_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      medium_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      low_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
    }),
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative")
        .nullable(),
      max: Yup.number()
        .typeError("Maximum pay rate must be a number")
        .nullable()
        .when("min", (min, schema) =>
          min !== undefined && min !== null
            ? schema.moreThan(
                min,
                "Maximum pay rate must be greater than the minimum"
              )
            : schema
        ),
    }),

    checkcondition: Yup.boolean().test(
      "checkcondition",
      "At least one field (high, medium, or low priority) must have a value.",
      function (value, context) {
        const { high_priority, medium_priority, low_priority } =
          context.parent.skillData;
        return (
          (high_priority && high_priority.length > 0) ||
          (medium_priority && medium_priority.length > 0) ||
          (low_priority && low_priority.length > 0)
        );
      }
    ),
    Date_Range: Yup.object()
      .nullable()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test(
        "start-end-date-not-same",
        "Start date and End date cannot be the same",
        function (value) {
          if (!value?.start_date || !value?.end_date) {
            return true;
          }
          return value.start_date !== value.end_date;
        }
      ),

    location_targets: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string(),
          id: Yup.mixed(),
        })
      )
      .test(
        "unique-content",
        "Location content must be unique",
        function (value) {
          if (!value) return true;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              if (contentSet.has(location.content)) {
                return false;
              }
              contentSet.add(location.content);
            }
          }
          return true;
        }
      ),
    pre_screen_questions: Yup.array()
      .of(
        Yup.string()
          .test(
            "no-empty-string",
            "Empty strings are not allowed",
            (value) => value !== "" && value !== undefined
          )
          .required("This field is required")
      )
      .nullable()
      .optional(),
    // reports_to: Yup.string().required("Report_To is required"),
    // req_id: Yup.string().required("Req_Id is required"),
    // bu_department_team: Yup.string().required("BU/Dept/Team is required"),
    // company_profile: Yup.string().required("Req_Id is required"),
    // // education: Yup.string().required("Highest Level of Education is required"),
    // shift: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // location_targets: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // certificates: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // employer_targets: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // languages: Yup.array()
    //   .min(1, "Please select at least one option")
    //   .required("This field is required"),
    // pay_rate: Yup.object().shape({
    //   min: Yup.number()
    //     .required("Minimum pay rate is required")
    //     .typeError("Minimum pay rate must be a number")
    //     .min(0, "Minimum pay rate cannot be negative"),
    //   max: Yup.number()
    //     .required("Maximum pay rate is required")
    //     .typeError("Maximum pay rate must be a number")
    //     .moreThan(
    //       Yup.ref("min"),
    //       "Maximum pay rate must be greater than the minimum"
    //     ),
    // }),
    // Date_Range: Yup.object()
    //   .shape({
    //     start_date: Yup.string(),
    //     end_date: Yup.string(),
    //   })
    //   .test("Date Range is required", (value) => {
    //     return value?.start_date || value?.end_date;
    //   }),
    // interview_type: Yup.array().of(
    //   Yup.string().required("Interview touch is required")
    // ),
  });

  const validationSchemaScore = Yup.object().shape({
    skillData: Yup.object({
      high_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      medium_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      low_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
    }),
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative")
        .nullable(),
      max: Yup.number()
        .typeError("Maximum pay rate must be a number")
        .nullable()
        .when("min", (min, schema) =>
          min !== undefined && min !== null
            ? schema.moreThan(
                min,
                "Maximum pay rate must be greater than the minimum"
              )
            : schema
        ),
    }),
    Date_Range: Yup.object()
      .nullable()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test(
        "start-end-date-not-same",
        "Start date and End date cannot be the same",
        function (value) {
          if (!value?.start_date || !value?.end_date) {
            return true;
          }
          return value.start_date !== value.end_date;
        }
      ),

    location_targets: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string(),
          id: Yup.mixed(),
        })
      )
      .test(
        "unique-content",
        "Location content must be unique",
        function (value) {
          if (!value) return true;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              if (contentSet.has(location.content)) {
                return false;
              }
              contentSet.add(location.content);
            }
          }
          return true;
        }
      ),
    pre_screen_questions: Yup.array()
      .of(
        Yup.string()
          .test(
            "no-empty-string",
            "Empty strings are not allowed",
            (value) => value !== "" && value !== undefined
          )
          .required("This field is required")
      )
      .nullable()
      .optional(),
  });

  const primaryViewData = [
    {
      title: "Title",
      component: "input",
      titleName: "job_title",
    },
    {
      title: "Company",
      component: "input",
      titleName: "company_name",
    },
    {
      title: "Industry",
      component: "dropDown",
      titleName: "industry",
      dropDownData: "available_industry",
    },
    {
      title:
        alignment === "View"
          ? "Hourly Target Rate"
          : "Minimal Hourly Target Rate",

      component: "input-select",
      value: jobValue.payRateValue.min,
      currency: jobValue.payRateValue.currency,

      titleName: "pay_rate",
      currency: "min",
      currencyValue: "currency",
    },
    ...(alignment === "Edit"
      ? [
          {
            title: "Maximal Hourly Target Rate",
            name: value(jobData?.job_details?.pay_rate),
            component: "input-select",
            value: jobValue.payRateValue.max,
            currency: jobValue.payRateValue.currency,

            titleName: "pay_rate",
            currency: "max",
            currencyValue: "currency",
          },
        ]
      : []),
    {
      title: "Seniority Level",
      name: "Senior",
      component: "dropDown",
      titleName: "management_level",
      dropDownData: "available_management_level",
    },
    {
      title: "Employment Type",

      component: "dropDown",
      value: jobValue.employment,
      data: jobValue.available_employment_types,
      titleName: "employment",
      dropDownData: "available_employment_types",
    },
    {
      title: "Location",
      component: "input",
      titleName: "location",
    },

    {
      title: "Date Range",
      name: "Oct 30 2024",
      component: "calender",
      titleName: "Date_Range",
    },
    ,
  ];

  const SecondaryViewData = [
    {
      title: "Occupational Classification",
      component: "dropDown",
      titleName: "occupational_classification",
      dropDownData: "available_occupational_classification",
    },
    {
      title: "Req ID",
      component: "input",
      titleName: "req_id",
    },
    {
      title: "Shift",
      component: "autoComplete",
      titleName: "shift",
      inputValue: "shift_value",
      placeholder: "Enter Shift",
    },
    {
      title: "Reports To",
      component: "input",
      titleName: "reports_to",
    },
    {
      title: "BU/Dept/Team",
      component: "input",
      titleName: "bu_department_team",
    },
    {
      title: "Company Profile",
      component: "dropDown",
      titleName: "company_profile",
      dropDownData: "available_company_profiles",
    },
  ];

  const additionalData = [
    {
      title: "Highest Level of Education",
      name: "Bachelor’s Degree",
      component: "dropDown",
      titleName: "education",
      dropDownData: "available_education",
      check: jobValue.score_education,
      toggleName: "score_education",
      type: "additional",
    },
    {
      title: "Location Targets",
      additional: [
        ...(jobData?.job_details?.location_targets?.map((location) => ({
          image: Image,
          name: location,
        })) || []),
      ],
      component: "autoComplete",
      check: false,
      titleName: "location_targets",
      placeholder: "Enter Location Targets",
      inputValue: "location_targets_value",
      check: jobValue.score_location_targets,
      toggleName: "score_location_targets",
      type: "additional",
    },
    {
      title: "Certificates",
      multi_additional: handleDynmaicList(jobData?.job_details?.certificates),
      component: "autoComplete",
      check: false,
      titleName: "certificates",
      inputValue: "certificates_value",
      placeholder: "Enter Certificates",
      check: jobValue.score_certificates,
      toggleName: "score_certificates",
      type: "additional",
    },
    {
      title: "Employer Targets",
      component: "autoComplete",
      check: false,
      titleName: "employer_targets",
      inputValue: "employer_targets_value",
      placeholder: "Enter Employer Targets",
      multi_additional: handleDynmaicList(
        jobData?.job_details?.employer_targets
      ),
      check: jobValue.score_employer_targets,
      toggleName: "score_employer_targets",
      type: "additional",
    },
    {
      title: "Languages",
      multi_additional: handleDynmaicList(jobData?.job_details?.languages),
      component: "autoComplete",
      titleName: "languages",
      inputValue: "languages_value",
      placeholder: "Enter Languages",
      check: jobValue.score_languages,
      toggleName: "score_languages",
      type: "additional",
    },
    {
      title: "Visa Status",
      name: jobData?.job_details?.visa_employment_needs?.content,
      component: "dropDown",
      titleName: "visa_status",
      dropDownData: "available_visa_status",
      check: jobValue.score_visa,
      toggleName: "score_visa",
      type: "additional",
    },
    {
      title: "Goverment / Security Classification",
      name: jobData?.job_details?.govt_security_clearance ? "Yes" : "NO",
      check: jobValue.govt_security_clearance,
      toggleName: "govt_security_clearance",
      type: "additional",
    },
    {
      title: "DEI",
      name: jobData?.job_details?.dei ? "Yes" : "NO",
      check: jobValue.dei,
      toggleName: "dei",
      type: "additional",
    },
  ];

  const headerData = [
    { name: "Skill" },
    { name: "Criticality" },
    { name: "Years" },
    ...(alignment === "Edit" ? [{ name: "" }] : []),
  ];

  const toggleRow = (index) => {
    setOpen((prev) => {
      const newState = { ...prev, [index]: !prev[index] };

      return newState;
    });
    setTimeout(() => {
      if (tableContainerRef.current) {
        const targetRow = tableContainerRef.current.querySelector(
          `.primary_${index}`
        );
        if (targetRow) {
          const targetCellTop = targetRow.getBoundingClientRect().top;
          const containerTop =
            tableContainerRef.current.getBoundingClientRect().top;
          const cellOffset = targetCellTop - containerTop;
          tableContainerRef.current.scrollTo({
            top: tableContainerRef.current.scrollTop + cellOffset - 60,
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  const menuItemData = [
    {
      name: "USD",
    },
    { name: "INR" },
  ];

  const handleDeleteSkillRow = (data, form, type) => {
    setDialogData({
      key: type,
      data: data,
      form: form,
    });
    setDeleteDialog(true);
  };

  const handleClose = () => {
    setSkillDialog(false);
  };

  const handleAddNewSkill = (form) => {
    setSkillDialog(true);
    setDialogData({
      title: "Create",
      classification: true,
      experience_in_yrs: "",
      criticality_score: 0,
      skill: "",
      form: form,
    });
  };

  const handleSkillClick = async (values, form) => {
    setButtonLoading(true);
    setTimeout(() => {
      const skillDataKey = values.key;
      const currentSkillData =
        dialogData.form.values["skillData"][skillDataKey];
      const { classification, skill, ...rest } = values;

      let updatedSkillData;

      updatedSkillData = [...currentSkillData, values];

      const formData = {
        ...dialogData.form.values["skillData"],
        [skillDataKey]: updatedSkillData,
      };
      dialogData.form.setFieldValue("skillData", formData);
      setSnackbarState({
        open: true,
        severity: "success",
        message: "updated Successfully",
      });
      setButtonLoading(false);
      setIsLoading(true);
      handleClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteSkill = () => {
    setButtonLoading(true);
    let filterData;

    setTimeout(() => {
      filterData = dialogData.form.values["skillData"][dialogData.key].filter(
        (item) => item !== dialogData.data
      );

      const formData = {
        ...dialogData.form.values["skillData"],
        [dialogData.key]: filterData,
      };
      dialogData.form.setFieldValue("skillData", formData);

      setSnackbarState({
        open: true,
        severity: "success",
        message: `${
          dialogData?.data?.classification
            ? dialogData?.data?.classification
            : dialogData.data.skill
        } Removed Successfully`,
      });
      setButtonLoading(false);
      handleDeleteDialogClose();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const fetchJobEdit = async (jobId) => {
    setIsLoading(true);

    try {
      const data = await dispatch(JobJobEdit(jobId));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const handleSkillToogleChange = (
    event,
    newAlignment,
    rowIndex,
    form,
    type
  ) => {
    if (newAlignment !== null) {
      const dataKey = type;

      const updatedSkillData = form?.values?.["skillData"]?.[dataKey]?.map(
        (row) =>
          Array.isArray(row?.skill)
            ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
            : { ...row }
      );

      const rowToUpdate = updatedSkillData?.[rowIndex];
      if (rowToUpdate) {
        rowToUpdate["category"] = newAlignment;
      }

      form.setFieldValue("skillData", {
        ...form?.values?.["skillData"],
        [dataKey]: updatedSkillData,
      });
    }
  };

  const handleSkillTextChange = (event, rowIndex, form, type, fieldName) => {
    const { value } = event.target;
    const dataKey = type;
    const updatedSkillData = form?.values?.["skillData"]?.[dataKey]?.map(
      (row) =>
        Array.isArray(row?.skill)
          ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
          : { ...row }
    );

    const rowToUpdate = updatedSkillData?.[rowIndex];
    if (rowToUpdate) {
      rowToUpdate[fieldName] = value;
    }

    form.setFieldValue("skillData", {
      ...form?.values?.["skillData"],
      [dataKey]: updatedSkillData,
    });
  };

  const onSeededDragEnd = (result, form) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const update = [...form.values["pre_screen_questions"]];
    const [removed] = update.splice(source.index, 1);
    update.splice(destination.index, 0, removed);
    form.setFieldValue("pre_screen_questions", update);
  };

  const handleSeededDelete = (question, index, form) => {
    setSeededDialog({
      content: question,
      index: index,
      form: form,
      deleteOpen: true,
      loading: false,
      addOpen: false,
    });
  };

  const handleDeleteSeeded = () => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const originalQuestions = [
        ...seededDialog?.form?.values["pre_screen_questions"],
      ];
      const updatedQuestions = originalQuestions.filter(
        (_, i) => i !== seededDialog?.index
      );

      seededDialog?.form?.setFieldValue(
        "pre_screen_questions",
        updatedQuestions
      );
      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Remvoved Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleSeededClose = () => {
    setSeededDialog({
      content: "",
      index: "",
      form: "",
      deleteOpen: false,
      loading: false,
      addOpen: false,
    });
  };
  const ordinalNumbers = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "sixth",
    "Seventh",
    "Eigth",
    "Ninth",
    "Tenth",
  ];
  const interviewTypes =
    alignment === "Edit"
      ? jobValue.interview_type
      : jobData?.job_details?.interview_type || [];
  const InterviewSettingData = interviewTypes.map((type, index) => ({
    title: `${ordinalNumbers[index] || `${index + 1}th`} Touch`,
    name: value(type),
    component: "input-list",
    titleName: "interview_type",
  }));

  const handleAddSeeded = (form) => {
    setSeededDialog((prev) => ({
      ...prev,
      addOpen: true,
      form: form,
    }));
  };

  const handleAddSubmit = (values) => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const update = [...seededDialog.form.values["pre_screen_questions"]];
      const updatedQuestions = [...update, values.question];

      seededDialog.form.setFieldValue("pre_screen_questions", updatedQuestions);
      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Added Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };
  const accordionDataView = [
    {
      title: "Primary Information",
      component: (
        <PrimaryInformation
          primaryViewData={primaryViewData}
          alignment={alignment}
          isLoading={isLoading}
          menuItemData={menuItemData}
        />
      ),
    },
    {
      title: "Secondary Information",
      component: (
        <>
          <SecondaryInformation
            SecondaryViewData={SecondaryViewData}
            alignment={alignment}
            isLoading={isLoading}
          />
          <SecondaryInformation
            SecondaryViewData={additionalData}
            alignment={alignment}
            isLoading={isLoading}
            marginTop={"3rem"}
            viewMarginTop={"1rem"}
          />
        </>
      ),
    },
    {
      title: "Skills",
      component: (
        <CommonGrid2 spacing={5} container>
          <CommonGrid2 size={12}>
            <Skills
              highPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.high_priority
                  : jobData?.job_details?.high_priority
              }
              lowPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.low_priority
                  : jobData?.job_details?.low_priority
              }
              mediumPriority={jobData?.job_details?.medium_priority}
              recommended={jobData?.job_details?.recommended}
              headerData={headerData}
              alignment={alignment}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleAddNewSkill={handleAddNewSkill}
              tableLoading={tableLoading}
              toggleRow={toggleRow}
              open={open}
              jobModifyData={jobModifyData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              handleSkillTextChange={handleSkillTextChange}
              isLoading={isLoading}
            />
          </CommonGrid2>
          {jobData &&
            jobData?.job_details &&
            jobData?.job_details?.interview_type?.length !== 0 && (
              <CommonGrid2 size={12}>
                <InterviewSettings
                  InterviewSettingData={InterviewSettingData}
                  alignment={alignment}
                  isLoading={isLoading}
                  rowSize={true}
                />
              </CommonGrid2>
            )}
          {(alignment === "Edit"
            ? true
            : jobData &&
              jobData?.job_details &&
              jobData?.job_details?.pre_screen_questions?.length !== 0) && (
            <CommonGrid2 size={12}>
              <SeededPreScreen
                alignment={alignment}
                preScreen={jobData?.job_details?.pre_screen_questions}
                onDragEnd={onSeededDragEnd}
                handleSeededDelete={handleSeededDelete}
                isLoading={isLoading}
                handleAddSeeded={handleAddSeeded}
              />
            </CommonGrid2>
          )}
        </CommonGrid2>
      ),
    },
  ];
  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSubmitJob = async (values, actions) => {
    setIsLoading(true);
    setEditButtonLoading(true);

    const result = (valueData) => {
      if (!valueData) return [];
      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const otherData = processedData.filter(
        (item) => item.name !== "extracted"
      );

      if (!extracted) {
        return [{ name: "extracted", content: [] }, ...otherData];
      }

      return [extracted, ...otherData];
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: values.location_targets.map((item) => item.content),
      high_priority: values.skillData.high_priority,
      low_priority: values.skillData.low_priority,
      medium_priority: values.skillData.medium_priority,
      recommended: values.skillData.recommended,
    };

    try {
      let jobData;
      if (!JobJobEditData.is_scored) {
        let param = {
          id: jobId,
        };
        await JobJobSubmit(jobValue.id, data);
        jobData = await JobIndustryScoreSubmit(param);
        setJobValue(jobModifyData);
        fetchJobEdit(jobId);
      } else {
        let params = {
          jem_status: "Active",
        };
        jobData = await JobJobSubmit(jobValue.id, data, params);
        setSnackbarState({
          open: true,
          severity: "success",
          message: jobData.message,
        });
        navigate("/jobs");
      }
      setIsLoading(false);
      setEditButtonLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
      setEditButtonLoading(false);
    }
  };

  const handleScrollToError = (errors) => {
    if (
      errors &&
      errors["skillData"] &&
      errors["skillData"]["extracted_hard_skills"] &&
      errors["skillData"]["extracted_hard_skills"]?.length !== 0
    ) {
      errors["skillData"]["extracted_hard_skills"].map((item, index) => {
        setOpen((prev) => {
          const newState = { ...prev, [index]: true };
          return newState;
        });
      });
    }
    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  };

  const resultHeaderData = ["Relevancy Index", "", "Relevancy Score", ""];

  const onRelevancyDragEnd = (result, form) => {
    const { source, destination } = result;
    if (!destination || destination.index === source.index) return;
    const reorderedCandidates = [...form.values["relevancy_index"]];
    const [removed] = reorderedCandidates.splice(source.index, 1);
    reorderedCandidates.splice(destination.index, 0, removed);
    form.setFieldValue("relevancy_index", reorderedCandidates);
  };

  const fetchJobsList = async (job_id) => {
    setIsLoading(true);
    try {
      const response = await GET(fixedJob(job_id));
      setSnackbarState({
        open: true,
        message: response?.data?.message || "Fix applied successfully",
        severity: "success",
      });
      if (alignment === "Edit") {
        setJobValue(jobModifyData);
        fetchJobEdit(jobId);
      }
      setIsLoading(false);
    } catch (err) {
      setSnackbarState({
        open: true,
        message: "An error occurred while applying the fix.",
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={jobValue}
        validationSchema={
          !JobJobEditData.is_scored ? validationSchemaScore : validationSchema
        }
        validateOnMount={true}
        onSubmit={async (values, actions) => {
          handleSubmitJob(values, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 container>
                <CommonGrid2
                  size={{ xs: 12, md: 4, sm: 12 }}
                  item
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography variant="h3">
                    {JobJobEditData?.inferred_title}
                  </Typography>
                </CommonGrid2>
                <CommonGrid2
                  item
                  size={{ xs: 12, md: 8, sm: 12 }}
                  gap="10px"
                  display="flex"
                  justifyContent={"end"}
                >
                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value="Generate Search Strings"
                    padding={"10px 20px"}
                    disabled={!JobJobEditData.is_scored}
                  />

                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    padding={"10px 20px"}
                    value="Share"
                  />

                  <CommonButton
                    variant="contained"
                    color="white"
                    background="#7F56D9"
                    fontWeight="600"
                    padding={"10px 20px"}
                    value={!JobJobEditData.is_scored ? "Score" : "Activate"}
                    type="submit"
                    disabled={editButtonLoading || isLoading}
                    loading={editButtonLoading}
                    onClick={() => {
                      setTimeout(() => {
                        handleScrollToError(errors);
                      }, 0);
                    }}
                  />
                </CommonGrid2>
              </Grid2>
              {alignment === "Edit" && JobJobEditData.is_scored && (
                <Result
                  jobData={JobJobEditData}
                  isLoading={isLoading}
                  fetchJobsList={fetchJobsList}
                  job_id={jobId}
                  toolTipOpen={false}
                />
              )}

              {values["relevancy_index"]?.length !== 0 && (
                <Grid2 marginTop={"3rem"}>
                  <RelevancyTable
                    alignment={alignment}
                    isLoading={isLoading}
                    resultHeaderData={resultHeaderData}
                    onDragEnd={onRelevancyDragEnd}
                    dragContent={values["relevancy_index"]}
                  />
                </Grid2>
              )}
              {accordionDataView.map((item, index) => (
                <CustomAccordion
                  // borderBottom={"1px solid #EAECF0"}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowUpIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography
                      variant="h4"
                      margin={"15px 0px"}
                      fontWeight={600}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {React.cloneElement(item.component, {
                      formikValues: values,
                      formikErrors: errors,
                      formikTouched: touched,
                      formikHandleChange: handleChange,
                      formikHandleBlur: handleBlur,
                      setFieldValue,
                    })}
                  </AccordionDetails>
                </CustomAccordion>
              ))}

              <AddNewSkillDialog
                open={skillDialog}
                handleClose={handleClose}
                dialogData={dialogData}
                jobModifyData={jobModifyData}
                handleSkillClick={handleSkillClick}
                buttonLoading={buttonLoading}
              />
              <SkillDeleteDialog
                deleteDialog={deleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDeleteSkill={handleDeleteSkill}
                buttonLoading={buttonLoading}
                title="Delete Skill"
                content={dialogData?.data?.skill}
              />

              <SkillDeleteDialog
                deleteDialog={seededDialog.deleteOpen}
                handleDeleteDialogClose={handleSeededClose}
                handleDeleteSkill={handleDeleteSeeded}
                buttonLoading={seededDialog.loading}
                title="Delete Seeded Pre-Screen"
                content={seededDialog.content}
              />
              <AddSeededDialog
                open={seededDialog.addOpen}
                handleDialogClose={handleSeededClose}
                handleSubmit={handleAddSubmit}
                loading={seededDialog.loading}
              />
            </Form>
          );
        }}
      </Formik>

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </div>
  );
};
export default ReviewJobPost;
