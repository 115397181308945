import { Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  addDameNotes,
  deleteDameNote,
  DownloadResume,
  getDameNotes,
  updateDameNote,
} from "../../../api/Api";
import {
  DELETE,
  GET,
  GETDOWNLOAD,
  POST,
  PUT,
} from "../../../redux/axios/Axios";
import AddUpdateNote from "../../common-components/AddUpdateNote";
import CommonAccordion from "../../common-components/CommonAccordion";
import DeleteNote from "../../common-components/DeleteNote";
import Interactions from "../jobs-summary/components/Interactions";
import Experience from "./components/Experience";
import MatchingSummary from "./components/MatchingSummary";
import Notes from "./components/Notes";
import Profile from "./components/Profile";
import Recommendations from "./components/Recommendations";
import StrengthWeak from "./components/StrengthWeak";

function JobsCandidate({
  matchedData,
  resumeId,
  resumeLoader,
  filteredResumeTab,
  fetchResumeData,
}) {
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);
  const query = new URLSearchParams(useLocation().search);
  const queryValue = query?.get("value");

  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleClickAddNote = () => {
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setNoteId("");
    setOpenNoteDialog(false);
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleDeleteNote = (row) => {
    setDeleteNoteDialogOpen(true);
    setNoteId(row?.id);
    setNoteList([
      {
        note: row?.note,
        interaction: row?.interaction,
        added_by: row?.added_by,
        created_at: row?.created_at,
      },
    ]);
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleUpdateNote = (row) => {
    if (row?.id) {
      setNoteId(row?.id);
      setOpenNoteDialog(true);
      setNoteList([
        {
          note: row?.note,
          interaction: row?.interaction,
          added_by: row?.added_by,
        },
      ]);
    }
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getDameNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async () => {
    setAddNoteLoading(true);
    if (resumeId) {
      const payload = noteList;
      if (noteId) {
        const res = await PUT(updateDameNote(noteId), noteList[0]);
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      } else {
        const res = await POST(addDameNotes(resumeId), payload);
        console.log(res?.data, "note");
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      }
      setAddNoteLoading(false);
      fetchNotesTableList(resumeId);
      setOpenNoteDialog(false);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
    }
  };

  const handleDeleteNoteById = async (row) => {
    const res = await DELETE(deleteDameNote(noteId));
    console.log(res?.data, "note");
    if (res?.success) {
      console.log(res?.data?.success, "tr");
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      fetchNotesTableList(resumeId);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
      setDeleteNoteDialogOpen(false);
      setNoteId("");
    } else {
    }
  };

  useEffect(() => {
    fetchNotesTableList(resumeId);
  }, [resumeId]);

  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const handleDownloadResume = () => {
    if (resumeId) {
      GETDOWNLOAD(DownloadResume(resumeId), {
        params: { type: "match_resume" },
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "docx"
              : "doc";

          const fileName = `${matchedData?.file_name}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  color: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  color: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              color: "error",
              message: "An unexpected error occurred.",
            });
          }
        });
    } else {
      setSnackbarState({
        open: true,
        color: "error",
        message: "Resume was not parsed successfully. Unable to download.",
      });
    }
  };

  const handleScoreChange = (label) => {
    fetchResumeData(resumeId, queryValue - 2, "", { spider_key: label });
  };

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Profile
          matchedData={matchedData}
          filteredResumeTab={filteredResumeTab}
          resumeId={resumeId}
          handleDownloadResume={handleDownloadResume}
          handleClickAddNote={handleClickAddNote}
          loading={resumeLoader}
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Matching Summary"}
          children={
            <MatchingSummary
              matchedData={matchedData}
              handleScoreChange={handleScoreChange}
              loading={resumeLoader}
              spiderGraph={true}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Strengths and Weaknesses"}
          children={
            <StrengthWeak
              size={6}
              matchedData={matchedData}
              loading={resumeLoader}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Recomendations"}
          children={
            <Recommendations matchedData={matchedData} loading={resumeLoader} />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Experience"}
          children={
            <Experience
              matchedData={matchedData?.experience}
              isLoading={resumeLoader}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Notes"}
          children={
            <Notes
              disabled={!matchedData?.rice_created}
              isShown={true}
              handleClickAddNote={handleClickAddNote}
              handleDeleteNoteClick={handleDeleteNote}
              handleUpdateNoteClick={handleUpdateNote}
              notesTableList={notesTableList}
              notesTableListLoading={notesTableListLoading || resumeLoader}
              isLoading={resumeLoader}
            />
          }
        />
      </Grid2>

      <Grid2 size={12}>
        <CommonAccordion
          title={"Interactions"}
          children={<Interactions tableLoader={resumeLoader} />}
        />
      </Grid2>

      <DeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
      />
      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={handleCreateNote}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
        addNoteLoading={addNoteLoading}
      />
    </Grid2>
  );
}

export default JobsCandidate;
