import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import CommonTextField from "../../../../component/Input";
import ToogleButton from "../../../../component/ToogleButton";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CustomTooltip,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { CustomizeTableCell, CustomTooltipField } from "../../style";

const SkillTable = ({
  rowData,
  name,
  tableLoading,
  toggleRow,
  open,
  jobModifyData,
  handleSkillToogleChange,
  tableContainerRef,
  headerData,
  handleDeleteSkillRow,
  handleSkillTextChange,
  alignment,
  isLoading,
}) => {
  return (
    <Field name={"skillData"}>
      {({ field, form }) => {
        const convertData =
          alignment === "Edit" ? form.values["skillData"][name] : rowData;
        return (
          <TableContainerBorder
            marginTop="1rem"
            ref={tableContainerRef}
            scrollStyle={true}
            height={convertData?.length >= 6 ? "400px" : "auto"}
          >
            <Table stickyHeader aria-label="sticky table" className="kathirvel">
              <TableHead>
                <TableRow>
                  {headerData.map((item, index) => {
                    return (
                      <CustomizeTableCell
                        key={index}
                        textAlign={index === 2 && "center"}
                        width={item.width}
                      >
                        {item.name}
                      </CustomizeTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              {isLoading || tableLoading ? (
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableRow>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <TableCell key={index}>
                          <HandleSkeletion height={30} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableBody>
                  {convertData?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <CustomizeTableCell
                          fontWeight={500}
                          sx={{ width: "40%" }}
                        >
                          {alignment === "Edit" ? (
                            <div style={{ position: "relative" }}>
                              <CommonTextField
                                padding="10px 0px 10px 10px"
                                width={"100%"}
                                label={"Skill"}
                                labelShrink={"10px"}
                                value={row?.skill}
                                fontSize={"14px"}
                                onChange={(event) =>
                                  handleSkillTextChange(
                                    event,
                                    index,
                                    form,
                                    name,
                                    "skill"
                                  )
                                }
                                error={
                                  form?.errors?.skillData?.[name]?.[index]?.[
                                    "skill"
                                  ] &&
                                  form?.touched?.skillData?.[name]?.[index]?.[
                                    "skill"
                                  ]
                                }
                              />
                              {form?.errors?.skillData?.[name]?.[index]?.[
                                "skill"
                              ] &&
                                form?.touched?.skillData?.[name]?.[index]?.[
                                  "skill"
                                ] && (
                                  <CustomTooltipField size={7}>
                                    {
                                      form?.errors?.skillData?.[name]?.[
                                        index
                                      ]?.["skill"]
                                    }
                                  </CustomTooltipField>
                                )}
                            </div>
                          ) : (
                            <Tooltip
                              disableHoverListener={!row?.about_the_skill}
                              title={
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.about_the_skill}
                                </span>
                              }
                              arrow
                              placement="top"
                            >
                              {row?.skill}
                            </Tooltip>
                          )}
                        </CustomizeTableCell>
                        <TableCell sx={{ width: "40%" }}>
                          <ToogleButton
                            alignment={row?.expertise_level}
                            padding={"5px 10px"}
                            ToogleData={
                              row?.type === "relevant_hard_skill" ||
                              row?.type === "extracted_hard_skill"
                                ? jobModifyData.criticalityHardData
                                : jobModifyData.criticalitySoftData
                            }
                            width={"max-content"}
                            handleChange={
                              alignment === "Edit"
                                ? (event, newAlignment) =>
                                    handleSkillToogleChange(
                                      event,
                                      newAlignment,
                                      index,
                                      form,
                                      name
                                    )
                                : () => {}
                            }
                            gap={"10px"}
                            fontSize={"12px"}
                            border={"none !important"}
                            borderRadius={"5px !important"}
                            activeColor={"#FFFF"}
                            color={"#667085"}
                            activeBackgroundColor={"#7F56D9"}
                            backgroundColor={"#EAECF0 !important"}
                            whiteSpace={"nowrap"}
                          />
                        </TableCell>

                        <CustomizeTableCell
                          fontWeight={500}
                          textAlign={"center"}
                          verticalAlign={"middle"}
                          width={"15%"}
                        >
                          {row?.type === "extracted_soft_skill" ||
                          row?.type ===
                            "relevant_soft_skill" ? null : alignment ===
                            "Edit" ? (
                            <div style={{ position: "relative" }}>
                              <CommonTextField
                                padding="7px 0px 10px 10px"
                                fontSize={"15px"}
                                width={"20px"}
                                label={"Year"}
                                labelShrink={"10px"}
                                value={row?.["experience_in_yrs"]}
                                onChange={(event) =>
                                  handleSkillTextChange(
                                    event,
                                    index,
                                    form,
                                    name,
                                    "experience_in_yrs"
                                  )
                                }
                                error={
                                  form?.errors?.skillData?.[name]?.[index]?.[
                                    "experience_in_yrs"
                                  ] &&
                                  form?.touched?.skillData?.[name]?.[index]?.[
                                    "experience_in_yrs"
                                  ]
                                }
                              />
                              {form?.errors?.skillData?.[name]?.[index]?.[
                                "experience_in_yrs"
                              ] &&
                                form?.touched?.skillData?.[name]?.[index]?.[
                                  "experience_in_yrs"
                                ] && (
                                  <CustomTooltipField size={7}>
                                    {
                                      form?.errors?.skillData?.[name]?.[
                                        index
                                      ]?.["experience_in_yrs"]
                                    }
                                  </CustomTooltipField>
                                )}
                            </div>
                          ) : (
                            row.experience_in_yrs
                          )}
                        </CustomizeTableCell>
                        {alignment === "Edit" && (
                          <CustomizeTableCell
                            verticalAlign={"middle"}
                            width={"5%"}
                          >
                            <CustomTooltip
                              title="Delete"
                              backgroundColor="#000"
                            >
                              <DeleteIcon
                                style={{
                                  cursor: "pointer",
                                  width: "22px",
                                }}
                                onClick={() =>
                                  handleDeleteSkillRow(row, form, name)
                                }
                              />
                            </CustomTooltip>
                          </CustomizeTableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainerBorder>
        );
      }}
    </Field>
  );
};

export default SkillTable;
