import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addJdNotes,
  deleteJdNote,
  fixedJob,
  getJdNotes,
  getJobDataDetail,
  updateJdNote,
} from "../../../api/Api";
import Image from "../../../assets/images/JobPrimary.png";
import CommonButton from "../../../component/Button";
import CommonLinearProgress from "../../../component/Linearprogress";
import SnackBar from "../../../component/SnackBar";
import ToogleButton from "../../../component/ToogleButton";
import {
  JobJobEdit,
  JobJobFormikData,
  JobJobReScore,
  JobJobSubmit,
} from "../../../redux/action/Job";
import { DELETE, GET, POST, PUT } from "../../../redux/axios/Axios";
import { getFileIcon } from "../../../utils/constants";
import CreateNote from "../../common-components/CreateNote";
import NewDeleteNote from "../../common-components/NewDeleteNote";
import {
  CommonGrid2,
  CustomAccordion,
} from "../../common-components/StyledComponents";
import {
  FileContainer,
  StyledLinearContainer,
} from "../../portal/quick-match/components/StyledComponent";
import AddSeededDialog from "../create-job/components/AddSeededDialog";
import InterviewSettings from "../create-job/components/InterviewSettings";
import SeededPreScreen from "../create-job/components/SeededPreScreen";
import DialogCandidateHub from "../jobs-candidate/components/DialogCandidateHub";
import Notes from "../jobs-candidate/components/Notes";
import { CommonTippy } from "../style";
import AddNewSkillDialog from "./Dialog";
import PrimaryInformation from "./primary-information";
import RelevancyTable from "./RelevancyTable";
import Result from "./result";
import SecondaryInformation from "./secondary-information";
import Skills from "./skills";
import SkillDeleteDialog from "./skills/SkillDeleteDialog";

const JobInformation = ({
  alignItems,
  // jobData,
  jobId,
  handleDownloadJdOpen,
  fetchData,
  handleClick,
  saveEditData,
  resumeTab,
}) => {
  const ToogleData = [
    { name: "View", value: "View" },
    { name: "Edit", value: "Edit" },
  ];
  const [alignment, setAlignment] = React.useState(alignItems || "View");
  const [skillDialog, setSkillDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    skill: "",
    experience_in_yrs: "",
    criticality_score: "",
    title: "Create",
    classificationData: [],
    classification: false,
    classificationDataValue: "",
    key: "",
  });
  const [noteList, setNoteList] = useState({
    open: false,
    laoding: false,
    noteId: "",
    type: "",
    note: "",
    created_at: "",
    job_history_reason: "",
    accecpting_less_more_candidates: "",
    locals_only: "",
    hybrid_days: "",
    project_scope: "",
    other_details: "",
    deadline_start: "",
    deadline_end: "",
  });

  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [notesTableList, setNotesTableList] = useState([]);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const JobJobEditData = useSelector((state) => state.jobs.jobJobEdit);
  const JobEditData = useSelector((state) => state.jobs.jobJobEditData);
  const [jobData, setJobData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [open, setOpen] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  let dates = moment(new Date());
  let today = dates.format("L");
  const tableContainerRef = React.useRef(null);
  const typeData = [
    { name: "Standard", value: "Blank" },
    { name: "Supplier Call", value: "Supplier Call" },
  ];
  const [files, setFiles] = useState([]);
  const [reScore, setReScore] = useState({
    disabled: false,
    noLoading: false,
    yesLoading: false,
    rescore: false,
    reRunOpen: false,
    noteOpen: false,
    file: false,
  });

  const [seededDialog, setSeededDialog] = useState({
    deleteOpen: false,
    loading: false,
    form: "",
    index: "",
    content: "",
    addOpen: false,
  });
  const [formikData, setFormikData] = useState({});

  const handleToogleChange = (event, newAlignment) => {
    if (
      JSON.stringify(JobEditData.formikData) !==
      JSON.stringify(JobEditData.jobData)
    ) {
      handleClick();
    } else if (newAlignment !== null) {
      if (newAlignment === "Edit" && jobData?.unscored) {
        setReScore((prev) => ({
          ...prev,
          rescore: true,
          reRunOpen: false,
          file: false,
        }));
      } else setAlignment(newAlignment);
    }
  };

  const value = (value) => (value ? value : "-");

  const jobModifyData = {
    job_title: "",
    company_name: "",
    industry: "",
    available_industry: [],
    payRateValue: {
      max: "",
      min: "",
      minCurrency: "USD",
      maxCurrency: "USD",
    },
    pay_rate: {
      max: "",
      min: "",
      currency: "USD",
    },
    management_level: "",
    available_management_level: [],
    employment: "",
    available_employment_types: [],
    Date_Range: {
      start_date: "",
      end_date: "",
    },
    criticalityHardData: [
      { name: "Familiar", value: "Familiar" },
      { name: "Hands-on", value: "Hands-on" },
      { name: "Expert", value: "Expert" },
    ],
    criticalitySoftData: [
      { name: "Novice", value: "Novice" },
      { name: "Proficient", value: "Proficient" },
      { name: "Expert", value: "Expert" },
    ],
    priorityData: [
      {
        name: "Medium",
        value: "Additional Qualities",
        key: "medium_priority",
      },
      {
        name: "High",
        value: "Complementary Skills",
        key: "low_priority",
      },
      {
        name: "Highest",
        value: "Core Skills",
        key: "high_priority",
      },
    ],
    skillType: [
      { name: "Hard Skill", value: "extracted_hard_skill" },
      { name: "Soft Skill", value: "extracted_soft_skill" },
    ],
    occupational_classification: "",
    available_occupational_classification: [],
    employeeTarget: "",

    autoCompleteValue: "",
    id: "",
    reports_to: "",
    req_id: "",
    bu_department_team: "",
    company_profile: "",
    available_company_profiles: [],
    education: "",
    available_education: "",
    dei: false,
    govt_security_clearance: false,
    languages: [],
    certificates: [],
    certificates_value: "",
    employer_targets: [],
    employer_targets_value: "",
    location: "",
    languages_value: "",
    location_targets: [],
    location_targets_value: "",
    shift: [],
    shift_value: "",
    skillData: {
      low_priority: [],
      high_priority: [],
      recommended: [],
      medium_priority: [],
    },
    score_certificates: false,
    score_education: false,
    score_employer_targets: false,
    score_hard_skills: false,
    score_languages: false,
    score_location_targets: false,
    score_soft_skills: false,
    score_visa: false,
    relevancy_index: [],
    interview_type: [],
    pre_screen_questions: [],
    available_visa_status: [],
    visa_status: "",
  };
  const [jobValue, setJobValue] = useState(jobModifyData);

  useEffect(() => {
    if (alignment === "View") {
      // setIconVisible(true);
      fetchJobDataDetail(jobId);
    } else if (alignment === "Edit") {
      setJobValue(jobModifyData);
      fetchJobEdit(jobId);
    }
  }, [alignment]);

  useEffect(() => {
    fetchNotesTableList(jobId);
  }, [jobId]);

  useEffect(() => {
    if (saveEditData?.view) setAlignment("View");
  }, [saveEditData]);

  const handleDynmaicList = (data) => {
    return data?.flatMap((item) =>
      item?.content?.map((contentItem) => ({
        name: item?.name,
        content: contentItem,
      }))
    );
  };
  useEffect(() => {
    if (Object.keys(JobJobEditData).length !== 0) {
      setJobValue({
        ...jobValue,
        job_title: JobJobEditData.job_title,
        company_name: JobJobEditData.company_name,
        industry: JobJobEditData.industry,
        available_industry: JobJobEditData.available_industry,
        payRateValue: {
          ...JobJobEditData.pay_rate,
          minCurrency: JobJobEditData.pay_rate?.currency,
          maxCurrency: JobJobEditData.pay_rate?.currency,
        },
        management_level: JobJobEditData.management_level,
        available_management_level: JobJobEditData.available_management_level,
        employment: JobJobEditData.employment,
        available_employment_types: JobJobEditData.available_employment_types,
        Date_Range: {
          start_date: JobJobEditData.start_date,
          end_date: JobJobEditData.end_date,
        },
        occupational_classification: JobJobEditData.occupational_classification,
        available_occupational_classification:
          JobJobEditData.available_occupational_classification,
        id: JobJobEditData.id,
        reports_to: JobJobEditData.reports_to,
        req_id: JobJobEditData.req_id,
        bu_department_team: JobJobEditData.bu_department_team,
        company_profile: JobJobEditData.company_profile,
        available_company_profiles: JobJobEditData.available_company_profiles,
        education: JobJobEditData.education,
        available_education: JobJobEditData.available_education,
        dei: JobJobEditData.dei,
        govt_security_clearance: JobJobEditData.govt_security_clearance,
        languages: handleDynmaicList(JobJobEditData.languages),
        certificates: handleDynmaicList(JobJobEditData.certificates),
        employer_targets: handleDynmaicList(JobJobEditData.employer_targets),
        location: JobJobEditData.location,
        location_targets: JobJobEditData.location_targets.map((item) => {
          return { content: item, id: "" };
        }),
        shift:
          JobJobEditData.shift.length === 0
            ? []
            : JobJobEditData?.shift?.map((item) => {
                return { content: item, id: "" };
              }),
        pay_rate: {
          ...JobJobEditData.pay_rate,
          max: JobJobEditData.pay_rate.max,
          min: JobJobEditData.pay_rate.min,
          currency: JobJobEditData.pay_rate.currency || "USD",
        },
        skillData: {
          extracted_hard_skills: JobJobEditData?.extracted_hard_skills,
          extracted_soft_skills: JobJobEditData?.extracted_soft_skills,
          low_priority: JobJobEditData.low_priority,
          high_priority: JobJobEditData.high_priority,
          recommended: JobJobEditData.recommended,
          medium_priority: JobJobEditData.medium_priority,
        },
        score_certificates: JobJobEditData.score_certificates,
        score_education: JobJobEditData.score_education,
        score_employer_targets: JobJobEditData.score_employer_targets,
        score_hard_skills: JobJobEditData.score_hard_skills,
        score_languages: JobJobEditData.score_languages,
        score_location_targets: JobJobEditData.score_location_targets,
        score_soft_skills: JobJobEditData.score_soft_skills,
        score_visa: JobJobEditData.score_visa,
        relevancy_index: JobJobEditData.relevancy_index,
        interview_type: JobJobEditData.interview_type,
        pre_screen_questions: JobJobEditData.pre_screen_questions,
        available_visa_status: JobJobEditData.available_visa_status,
        visa_status: JobJobEditData.visa_status,
      });
    }
  }, [JobJobEditData]);

  const primaryViewData = [
    {
      title: "Title",
      name: value(jobData?.job_details?.job_title),
      component: "input",
      titleName: "job_title",
    },
    {
      title: "Company",
      name: value(jobData?.job_details?.company_name),
      component: "input",
      titleName: "company_name",
    },
    {
      title: "Industry",
      name: value(jobData?.job_details?.industry),
      component: "dropDown",
      titleName: "industry",
      dropDownData: "available_industry",
    },
    {
      title:
        alignment === "View"
          ? "Hourly Target Rate"
          : "Minimal Hourly Target Rate",
      name: value(jobData?.job_details?.pay_rate),
      component: "input-select",
      value: jobValue.payRateValue.min,
      currency: jobValue.payRateValue.currency,

      titleName: "pay_rate",
      currency: "min",
      currencyValue: "currency",
    },
    ...(alignment === "Edit"
      ? [
          {
            title: "Maximal Hourly Target Rate",
            name: value(jobData?.job_details?.pay_rate),
            component: "input-select",
            value: jobValue.payRateValue.max,
            currency: jobValue.payRateValue.currency,

            titleName: "pay_rate",
            currency: "max",
            currencyValue: "currency",
          },
        ]
      : []),
    {
      title: "Seniority Level",
      name: "Senior",
      component: "dropDown",
      titleName: "management_level",
      dropDownData: "available_management_level",
    },
    {
      title: "Employment Type",
      name: value(jobData?.job_details?.employment),
      component: "dropDown",
      value: jobValue.employment,
      data: jobValue.available_employment_types,
      titleName: "employment",
      dropDownData: "available_employment_types",
    },
    {
      title: "Location",
      image: jobData?.job_details?.location ? Image : null,
      name: value(jobData?.job_details?.location),
      component: "input",
      titleName: "location",
    },
    ...(alignment === "View"
      ? [
          {
            title: "Start Date",
            name: value(jobData?.job_details?.start_date),
          },
          {
            title: "End Date",
            name: value(jobData?.job_details?.end_date),
          },
        ]
      : [
          {
            title: "Date Range",
            name: "Oct 30 2024",
            component: "calender",
            titleName: "Date_Range",
          },
        ]),
  ];

  const SecondaryViewData = [
    {
      title: "Occupational Classification",
      name: value(jobData?.job_details?.occupational_classification),
      component: "dropDown",
      titleName: "occupational_classification",
      dropDownData: "available_occupational_classification",
    },
    {
      title: "Req ID",
      name: value(jobData?.job_details?.req_id),
      component: "input",
      titleName: "req_id",
    },
    {
      title: "Shift",
      shift: [jobData?.job_details?.shift],
      component: "autoComplete",
      titleName: "shift",
      inputValue: "shift_value",
      placeholder: "Enter Shift",
    },
    {
      title: "Reports To",
      name: value(jobData?.job_details?.reports_to),
      component: "input",
      titleName: "reports_to",
    },
    {
      title: "BU/Dept/Team",
      name: value(jobData?.job_details?.bu_department_team),
      component: "input",
      titleName: "bu_department_team",
    },
    {
      title: "Company Profile",
      name: jobData?.job_details?.company_profile,
      component: "dropDown",
      titleName: "company_profile",
      dropDownData: "available_company_profiles",
    },
  ];

  const additionalData = [
    {
      title: "Highest Level of Education",
      name: jobData?.job_details?.education,
      component: "dropDown",
      titleName: "education",
      dropDownData: "available_education",
      check: jobValue.score_education,
      toggleName: "score_education",
      type: "additional",
    },
    {
      title: "Location Targets",
      additional: [
        ...(jobData?.job_details?.location_targets?.map((location) => ({
          image: Image,
          name: location,
        })) || []),
      ],
      component: "autoComplete",
      check: false,
      titleName: "location_targets",
      inputValue: "location_targets_value",
      placeholder: "Enter Location Targets",
      check: jobValue.score_location_targets,
      toggleName: "score_location_targets",
      type: "additional",
    },
    {
      title: "Certificates",
      multi_additional: handleDynmaicList(jobData?.job_details?.certificates),
      component: "autoComplete",
      check: false,
      titleName: "certificates",
      inputValue: "certificates_value",
      placeholder: "Enter Certificates",
      check: jobValue.score_certificates,
      toggleName: "score_certificates",
      type: "additional",
    },
    {
      title: "Employer Targets",
      component: "autoComplete",
      check: false,
      titleName: "employer_targets",
      inputValue: "employer_targets_value",
      placeholder: "Enter Employer Targets",
      multi_additional: handleDynmaicList(
        jobData?.job_details?.employer_targets
      ),
      check: jobValue.score_employer_targets,
      toggleName: "score_employer_targets",
      type: "additional",
    },
    {
      title: "Languages",
      multi_additional: handleDynmaicList(jobData?.job_details?.languages),
      component: "autoComplete",
      titleName: "languages",
      inputValue: "languages_value",
      placeholder: "Enter Languages",
      check: jobValue.score_languages,
      toggleName: "score_languages",
      type: "additional",
    },
    {
      title: "Visa Status",
      name: jobData?.job_details?.visa_employment_needs?.content,
      component: "dropDown",
      titleName: "visa_status",
      dropDownData: "available_visa_status",
      check: jobValue.score_visa,
      toggleName: "score_visa",
      type: "additional",
    },
    {
      title: "Goverment / Security Classification",
      name: jobData?.job_details?.govt_security_clearance ? "Yes" : "NO",
      check: jobValue.govt_security_clearance,
      toggleName: "govt_security_clearance",
      type: "additional",
      size: true,
    },
    {
      title: "DEI",
      name: jobData?.job_details?.dei ? "Yes" : "NO",
      check: jobValue.dei,
      toggleName: "dei",
      size: true,
      type: "additional",
    },
  ];

  const headerData = [
    { name: "Skill" },
    { name: "Criticality" },
    { name: "Years" },
    ...(alignment === "Edit" ? [{ name: "" }] : []),
  ];

  const toggleRow = (index) => {
    setOpen((prev) => {
      const newState = { ...prev, [index]: !prev[index] };

      return newState;
    });
    setTimeout(() => {
      if (tableContainerRef.current) {
        const targetRow = tableContainerRef.current.querySelector(
          `.primary_${index}`
        );
        if (targetRow) {
          const targetCellTop = targetRow.getBoundingClientRect().top;
          const containerTop =
            tableContainerRef.current.getBoundingClientRect().top;
          const cellOffset = targetCellTop - containerTop;
          tableContainerRef.current.scrollTo({
            top: tableContainerRef.current.scrollTop + cellOffset - 60,
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  const menuItemData = [
    {
      name: "USD",
    },
    { name: "INR" },
  ];

  const handleDeleteSkillRow = (data, form, type) => {
    setDialogData({
      key: type,
      data: data,
      form: form,
    });
    setDeleteDialog(true);
  };

  const handleClose = () => {
    setSkillDialog(false);
  };

  const handleAddNewSkill = (form) => {
    setSkillDialog(true);
    setDialogData({
      title: "Create",
      classification: true,
      // bucketData: jobValue.bucketData,
      experience_in_yrs: "",
      criticality_score: 0,
      skill: "",
      form: form,
    });
  };

  const handleSkillClick = async (values, form) => {
    setButtonLoading(true);
    setTimeout(() => {
      const skillDataKey = values.key;
      const currentSkillData =
        dialogData.form.values["skillData"][skillDataKey];
      const { classification, skill, ...rest } = values;

      let updatedSkillData;

      updatedSkillData = [...currentSkillData, values];

      const formData = {
        ...dialogData.form.values["skillData"],
        [skillDataKey]: updatedSkillData,
      };

      dialogData.form.setFieldValue("skillData", formData);
      setSnackbarState({
        open: true,
        severity: "success",
        message: "updated Successfully",
      });
      setButtonLoading(false);
      setIsLoading(true);
      handleClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteSkill = () => {
    setButtonLoading(true);
    let filterData;

    setTimeout(() => {
      filterData = dialogData.form.values["skillData"][dialogData.key].filter(
        (item) => item !== dialogData.data
      );

      const formData = {
        ...dialogData.form.values["skillData"],
        [dialogData.key]: filterData,
      };
      dialogData.form.setFieldValue("skillData", formData);

      setSnackbarState({
        open: true,
        severity: "success",
        message: `${
          dialogData?.data?.classification
            ? dialogData?.data?.classification
            : dialogData.data.skill
        } Removed Successfully`,
      });
      setButtonLoading(false);
      handleDeleteDialogClose();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const fetchJobDataDetail = async (jobId) => {
    try {
      setIsLoading(true);
      setTableLoading(true);
      const res = await GET(getJobDataDetail(jobId));
      setJobData(res?.data);
      setIsLoading(false);
      setTableLoading(false);
      handleRescoreClose();
    } catch (error) {
      setIsLoading(false);
      setTableLoading(false);
      handleRescoreClose();
    }
  };

  const fetchJobsList = async (job_id) => {
    setIsLoading(true);
    try {
      const response = await GET(fixedJob(job_id));
      setSnackbarState({
        open: true,
        message: response?.data?.message || "Fix applied successfully",
        severity: "success",
      });
      if (alignment === "Edit") {
        setJobValue(jobModifyData);
        fetchJobEdit(jobId);
      } else {
        fetchJobDataDetail(jobId);
      }
      setIsLoading(false);
    } catch (err) {
      setSnackbarState({
        open: true,
        message: "An error occurred while applying the fix.",
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  //notes
  const handleClickAddNote = () => {
    setNoteList((prev) => ({
      ...prev,
      open: true,
      loading: false,
      noteId: "",
      type: "",
      note: "",
      created_at: "",
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleCloseNoteDialog = () => {
    setNoteList((prev) => ({
      ...prev,
      open: false,
      loading: false,
      noteId: "",
      type: "",
      note: "",
      created_at: "",
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleDeleteNoteClick = (row, index) => {
    setDeleteNoteDialogOpen(true);
    setNoteList((prev) => ({
      ...prev,
      open: false,
      loading: false,
      noteId: row?.id,
      type: row?.type,
      note: row?.note,
      created_at: row?.created_at,
      job_history_reason: "",
      accecpting_less_more_candidates: "",
      locals_only: "",
      hybrid_days: "",
      project_scope: "",
      other_details: "",
      deadline_start: "",
      deadline_end: "",
    }));
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    handleCloseNoteDialog();
  };

  const handleUpdateNoteClick = (row) => {
    setNoteList((prev) => ({
      ...prev,
      open: true,
      loading: false,
      noteId: row?.id,
      type: row?.type,
      note: row?.note,
      created_at: row?.created_at,
      job_history_reason: row?.job_history_reason,
      accecpting_less_more_candidates: row?.accecpting_less_more_candidates,
      locals_only: row?.locals_only,
      hybrid_days: row?.hybrid_days,
      project_scope: row?.project_scope,
      other_details: row?.note,
      deadline_start: row?.deadline_start,
      deadline_end: row?.deadline_end,
    }));
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getJdNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async (values) => {
    setNoteList((prev) => ({ ...prev, loading: true }));
    let payload;
    if (values.type === "Blank") {
      payload = {
        note: values.note,
        interaction: "Email",
        added_by: "username",
        type: values.type,
      };
    } else {
      payload = {
        note: values.other_details,
        type: values.type,
        added_by: "username",
        hybrid_days: values.hybrid_days,
        deadline_start: values["Date_range"]?.["deadline_start"],
        deadline_end: values["Date_range"]?.["deadline_end"],
        job_history_type: values.job_history_type,
        job_history_reason: values.job_history_reason,
        accecpting_less_more_candidates: values.accecpting_less_more_candidates,
        locals_only: values.locals_only,
        project_scope: values.project_scope,
      };
    }

    if (noteList.noteId) {
      try {
        const res = await PUT(updateJdNote(noteList.noteId), payload);
        setSnackbarState({
          open: true,
          severity: "success",
          message: res?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
    } else {
      try {
        const res = await POST(addJdNotes(jobId), [payload]);
        setSnackbarState({
          open: true,
          severity: "success",
          message: res?.data?.message,
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
    }
    handleCloseNoteDialog();
    setReScore((prev) => ({
      ...prev,
      rescore: true,
      noteOpen: true,
      file: false,
      reRunOpen: false,
    }));

    fetchNotesTableList(jobId);
  };

  const handleDeleteNoteById = async () => {
    setNoteList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await DELETE(deleteJdNote(noteList.noteId));
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      fetchNotesTableList(jobId);
      handleDeleteNoteDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.message,
      });
      handleCloseNoteDialog();
    }
  };

  const fetchJobEdit = async (jobId) => {
    setIsLoading(true);

    try {
      const data = await dispatch(JobJobEdit(jobId));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const handleSkillToogleChange = (
    event,
    newAlignment,
    rowIndex,
    form,
    type
  ) => {
    if (newAlignment !== null) {
      const dataKey = type;

      const updatedSkillData = form?.values?.["skillData"]?.[dataKey]?.map(
        (row) =>
          Array.isArray(row?.skill)
            ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
            : { ...row }
      );

      const rowToUpdate = updatedSkillData?.[rowIndex];
      if (rowToUpdate) {
        rowToUpdate["expertise_level"] = newAlignment;
      }

      form.setFieldValue("skillData", {
        ...form?.values?.["skillData"],
        [dataKey]: updatedSkillData,
      });
    }
  };

  const handleSkillTextChange = (event, rowIndex, form, type, fieldName) => {
    const { value } = event.target;
    const dataKey = type;
    const updatedSkillData = form?.values?.["skillData"]?.[dataKey]?.map(
      (row) =>
        Array.isArray(row?.skill)
          ? { ...row, skill: row.skill.map((skill) => ({ ...skill })) }
          : { ...row }
    );

    const rowToUpdate = updatedSkillData?.[rowIndex];
    if (rowToUpdate) {
      rowToUpdate[fieldName] = value;
    }

    form.setFieldValue("skillData", {
      ...form?.values?.["skillData"],
      [dataKey]: updatedSkillData,
    });
  };

  const onSeededDragEnd = (result, form) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const update = [...form.values["pre_screen_questions"]];
    const [removed] = update.splice(source.index, 1);
    update.splice(destination.index, 0, removed);
    form.setFieldValue("pre_screen_questions", update);
  };

  const handleSeededDelete = (question, index, form) => {
    setSeededDialog({
      content: question,
      index: index,
      form: form,
      deleteOpen: true,
      loading: false,
      addOpen: false,
    });
  };

  const handleDeleteSeeded = () => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const originalQuestions = [
        ...seededDialog?.form?.values["pre_screen_questions"],
      ];
      const updatedQuestions = originalQuestions.filter(
        (_, i) => i !== seededDialog?.index
      );

      seededDialog?.form?.setFieldValue(
        "pre_screen_questions",
        updatedQuestions
      );

      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Remvoved Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };

  const handleSeededClose = () => {
    setSeededDialog({
      content: "",
      index: "",
      form: "",
      deleteOpen: false,
      loading: false,
      addOpen: false,
    });
  };
  const ordinalNumbers = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "sixth",
    "Seventh",
    "Eigth",
    "Ninth",
    "Tenth",
  ];
  const interviewTypes =
    alignment === "Edit"
      ? jobValue.interview_type
      : jobData?.job_details?.interview_type || [];
  const InterviewSettingData = interviewTypes.map((type, index) => ({
    title: `${ordinalNumbers[index] || `${index + 1}th`} Touch`,
    name: value(type),
    component: "input-list",
    titleName: "interview_type",
  }));

  const handleAddSeeded = (form) => {
    setSeededDialog((prev) => ({
      ...prev,
      addOpen: true,
      form: form,
    }));
  };

  const handleAddSubmit = (values) => {
    setSeededDialog((prev) => ({
      ...prev,
      loading: true,
    }));

    setTimeout(() => {
      const update = [...seededDialog.form.values["pre_screen_questions"]];
      const updatedQuestions = [...update, values.question];

      seededDialog.form.setFieldValue("pre_screen_questions", updatedQuestions);
      setSnackbarState({
        open: true,
        severity: "success",
        message: "Seeded Added Successfully",
      });
      setIsLoading(true);
      handleSeededClose();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 1000);
  };
  const accordionDataView = [
    {
      title: "Primary Information",
      component: (
        <PrimaryInformation
          primaryViewData={primaryViewData}
          alignment={alignment}
          isLoading={isLoading}
          menuItemData={menuItemData}
        />
      ),
    },
    {
      title: "Secondary Information",
      component: (
        <>
          <SecondaryInformation
            SecondaryViewData={SecondaryViewData}
            alignment={alignment}
            isLoading={isLoading}
          />
          <SecondaryInformation
            SecondaryViewData={additionalData}
            alignment={alignment}
            isLoading={isLoading}
            marginTop={"3rem"}
            viewMarginTop={"1rem"}
          />
        </>
      ),
    },
    {
      title: "Skills",
      component: (
        <CommonGrid2 spacing={5} container>
          <CommonGrid2 size={12}>
            <Skills
              highPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.high_priority
                  : jobData?.job_details?.high_priority
              }
              lowPriority={
                alignment === "Edit"
                  ? jobValue?.skillData?.low_priority
                  : jobData?.job_details?.low_priority
              }
              mediumPriority={jobData?.job_details?.medium_priority}
              recommended={jobData?.job_details?.recommended}
              headerData={headerData}
              alignment={alignment}
              handleDeleteSkillRow={handleDeleteSkillRow}
              handleAddNewSkill={handleAddNewSkill}
              tableLoading={tableLoading}
              toggleRow={toggleRow}
              open={open}
              jobModifyData={jobModifyData}
              handleSkillToogleChange={handleSkillToogleChange}
              tableContainerRef={tableContainerRef}
              handleSkillTextChange={handleSkillTextChange}
              isLoading={isLoading}
            />
          </CommonGrid2>
          {jobData &&
            jobData?.job_details &&
            jobData?.job_details?.interview_type?.length !== 0 && (
              <CommonGrid2 size={12}>
                <InterviewSettings
                  InterviewSettingData={InterviewSettingData}
                  alignment={alignment}
                  isLoading={isLoading}
                  rowSize={true}
                />
              </CommonGrid2>
            )}
          {(alignment === "Edit"
            ? true
            : jobData &&
              jobData?.job_details &&
              jobData?.job_details?.pre_screen_questions?.length !== 0) && (
            <CommonGrid2 size={12}>
              <SeededPreScreen
                alignment={alignment}
                preScreen={jobData?.job_details?.pre_screen_questions}
                onDragEnd={onSeededDragEnd}
                handleSeededDelete={handleSeededDelete}
                isLoading={isLoading}
                handleAddSeeded={handleAddSeeded}
              />
            </CommonGrid2>
          )}
        </CommonGrid2>
      ),
    },
    {
      title: "Notes",
      component: (
        <Notes
          isShown={true}
          handleClickAddNote={handleClickAddNote}
          handleDeleteNoteClick={handleDeleteNoteClick}
          handleUpdateNoteClick={handleUpdateNoteClick}
          notesTableList={notesTableList}
          notesTableListLoading={notesTableListLoading || isLoading}
          isLoading={isLoading}
        />
      ),
    },
  ];
  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSubmitJob = async (values, actions) => {
    setIsLoading(true);
    setEditButtonLoading(true);

    const result = (valueData) => {
      if (!valueData) return [];
      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const otherData = processedData.filter(
        (item) => item.name !== "extracted"
      );

      if (!extracted) {
        return [{ name: "extracted", content: [] }, ...otherData];
      }

      return [extracted, ...otherData];
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: values.location_targets.map((item) => item.content),
      high_priority: values.skillData.high_priority,
      low_priority: values.skillData.low_priority,
      medium_priority: values.skillData.medium_priority,
      recommended: values.skillData.recommended,
    };

    try {
      dispatch(JobJobFormikData(values, values));
      const jobEdit = await JobJobSubmit(jobValue.id, data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: jobEdit.message,
      });
      setAlignment("View");
      setIsLoading(false);
      setEditButtonLoading(false);
    } catch (error) {
      dispatch(JobJobFormikData(jobValue, values));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
      setEditButtonLoading(false);
    }
  };

  const handleScrollToError = (errors) => {
    if (
      errors &&
      errors["skillData"] &&
      errors["skillData"]["extracted_hard_skills"] &&
      errors["skillData"]["extracted_hard_skills"]?.length !== 0
    ) {
      errors["skillData"]["extracted_hard_skills"].map((item, index) => {
        setOpen((prev) => {
          const newState = { ...prev, [index]: true };
          return newState;
        });
      });
    }
    setTimeout(() => {
      const errorElement = document.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  };

  const Footer = () => {
    return (
      <CommonGrid2 display={"flex"} gap={"1rem"}>
        {reScore.reRunOpen ? (
          <CommonButton
            value={"Yes"}
            width={"100%"}
            padding={"10px"}
            marginTop={reScore.noteOpen ? "2rem" : "1rem"}
            fontWeight={"700"}
            fontSize={"15px"}
            disabled={reScore.disabled}
            loading={reScore.yesLoading}
            onClick={() => handleClickScore("re-run", false)}
          />
        ) : (
          <>
            <CommonButton
              value={"No"}
              variant="outlined"
              color="#344054"
              width={"100%"}
              padding={"10px"}
              marginTop={"2rem"}
              fontWeight={"700"}
              fontSize={"15px"}
              border={"#D0D5DD"}
              onClick={() =>
                handleClickScore("no", reScore.noteOpen ? false : true)
              }
              disabled={reScore.disabled}
              loading={reScore.noLoading}
            />

            <CommonButton
              value={"Yes"}
              width={"100%"}
              padding={"10px"}
              marginTop={"2rem"}
              fontWeight={"700"}
              fontSize={"15px"}
              disabled={reScore.disabled}
              loading={reScore.yesLoading}
              onClick={() =>
                handleClickScore("yes", reScore.noteOpen ? false : true)
              }
            />
          </>
        )}
      </CommonGrid2>
    );
  };

  const handleClickScore = async (condition, check) => {
    setReScore((prev) => ({
      ...prev,
      yesLoading: condition === "yes" || condition === "re-run" ? true : false,
      noLoading: condition === "no" ? true : false,
      disabled: true,
      file: condition == "yes" || condition === "re-run" ? true : false,
    }));
    setIsLoading(true);
    setFiles(
      resumeTab.map((item) => ({
        title: item.name,
        progress: 0,
        buffer: 0,
        type: item.file_name,
        name: item.file_name,
        visible: false,
      }))
    );

    let payload = {
      score: condition === "re-run" || condition === "yes" ? true : false,
    };

    try {
      const response = await JobJobReScore(jobId, payload);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse, check, condition);
          } catch (e) {
            setFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.title === jsonResponse.data.current_resume
                  ? {
                      ...f,
                      visible: true,
                    }
                  : { ...f }
              )
            );
            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
            setIsLoading(false);
            setReScore({
              noLoading: false,
              disabled: false,
              yesLoading: false,
              rescore: true,
              reRunOpen: condition === "re-run" ? true : false,
              noteOpen: condition === "re-run" ? false : true,
              file: condition == "yes" || condition === "re-run" ? true : false,
            });
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse, check, condition);
        } catch (e) {
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.title === jsonResponse.data.current_resume
                ? {
                    ...f,
                    visible: true,
                    delete: true,
                  }
                : { ...f, delete: true }
            )
          );
          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
          setIsLoading(false);
          setReScore({
            noLoading: false,
            disabled: false,
            yesLoading: false,
            rescore: true,
            reRunOpen: condition === "re-run" ? true : false,
            noteOpen: condition === "re-run" ? false : true,
            file: condition == "yes" || condition === "re-run" ? true : false,
          });
        }
      }
    } catch (error) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          visible: true,
          message: "Error during the request",
        }))
      );
      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });
      setIsLoading(false);
      setReScore({
        noLoading: false,
        disabled: false,
        yesLoading: false,
        rescore: true,
        reRunOpen: condition === "re-run" ? true : false,
        noteOpen: condition === "re-run" ? false : true,
        file: condition == "yes" || condition === "re-run" ? true : false,
      });
    }
  };

  const processJsonResponse = async (jsonResponse, check, condition) => {
    const { success, data, message } = jsonResponse;

    if (success && data?.percent !== undefined) {
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.title === data.current_resume
            ? {
                ...f,
                progress: data.percent,
                buffer: data.percent,
                visible: false,
              }
            : f
        )
      );
    } else if (data?.response) {
      if (check) {
        setAlignment("Edit");
      } else {
        await fetchData();
        await fetchJobDataDetail(jobId);
      }
      handleRescoreClose();
      setSnackbarState({
        open: true,
        severity: "success",
        message: message,
        // duration: 500,
      });
    } else if (!success) {
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.title === data.current_resume || data.length == 0
            ? {
                ...f,
                visible: true,
                message: message,
              }
            : { ...f, delete: true }
        )
      );
      setIsLoading(false);
      setReScore({
        noLoading: false,
        disabled: false,
        yesLoading: false,
        rescore: true,
        reRunOpen: condition === "re-run" ? true : false,
        noteOpen: condition === "re-run" ? false : true,
        file: condition == "yes" || condition === "re-run" ? true : false,
      });
    }
  };

  const handleRescoreClose = () => {
    setIsLoading(false);
    setReScore({
      noLoading: false,
      disabled: false,
      yesLoading: false,
      rescore: false,
      reRunOpen: false,
      noteOpen: false,
      file: false,
    });
  };

  const handleClickRerun = () => {
    setReScore({
      noLoading: false,
      disabled: false,
      yesLoading: false,
      rescore: true,
      reRunOpen: true,
      noteOpen: false,
      file: false,
    });
  };
  const resultHeaderData = ["Relevancy Index", "", "Relevancy Score", ""];

  const onRelevancyDragEnd = (result, form) => {
    const { source, destination } = result;
    if (!destination || destination.index === source.index) return;
    const reorderedCandidates = [...form.values["relevancy_index"]];
    const [removed] = reorderedCandidates.splice(source.index, 1);
    reorderedCandidates.splice(destination.index, 0, removed);
    form.setFieldValue("relevancy_index", reorderedCandidates);
  };

  useEffect(() => {
    dispatch(JobJobFormikData(jobValue, formikData));
  }, [formikData]);

  const noteUpperData = [
    {
      title: "Client",
      component: "input",
      fontSize: "15px",
      value: jobData?.job_details?.company_name,
      placeholderFontSize: "15px",
      placeholder: "Enter the name",
    },
    {
      title: "Date",
      component: "input",
      fontSize: "15px",
      value: moment(today).format("DD MMM YYYY"),
      placeholderFontSize: "15px",
      placeholder: "Enter the number",
    },
    {
      title: "Position",
      fontSize: "15px",
      value: jobData?.job_details?.job_title,
      placeholderFontSize: "15px",
      placeholder: "Enter H1B Holder Company Name",
    },
    {
      title: "Job ID",
      fontSize: "15px",
      value: jobData?.job_details?.req_id,
      placeholderFontSize: "15px",
      placeholder: "Enter H1B Holder Company Name",
    },
  ];
  const validationSchema = Yup.object().shape({
    pay_rate: Yup.object().shape({
      min: Yup.number()
        .typeError("Minimum pay rate must be a number")
        .min(0, "Minimum pay rate cannot be negative")
        .nullable(),
      max: Yup.number()
        .typeError("Maximum pay rate must be a number")
        .nullable()
        .when("min", (min, schema) =>
          min !== undefined && min !== null
            ? schema.moreThan(
                min,
                "Maximum pay rate must be greater than the minimum"
              )
            : schema
        ),
    }),
    // req_id: Yup.number().typeError("Req Id must be a number").nullable(),

    Date_Range: Yup.object()
      .nullable()
      .shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
      })
      .test(
        "start-end-date-not-same",
        "Start date and End date cannot be the same",
        function (value) {
          if (!value?.start_date || !value?.end_date) {
            return true;
          }
          return value.start_date !== value.end_date;
        }
      ),
    location_targets: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          content: Yup.string(),
          id: Yup.mixed(),
        })
      )
      .test(
        "unique-content",
        "Location content must be unique",
        function (value) {
          if (!value) return true;
          const contentSet = new Set();
          for (let location of value) {
            if (location.content) {
              if (contentSet.has(location.content)) {
                return false;
              }
              contentSet.add(location.content);
            }
          }
          return true;
        }
      ),
    pre_screen_questions: Yup.array()
      .of(
        Yup.string()
          .test(
            "no-empty-string",
            "Empty strings are not allowed",
            (value) => value !== "" && value !== undefined
          )
          .required("This field is required")
      )
      .nullable()
      .optional(),
    skillData: Yup.object({
      high_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      medium_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
      low_priority: Yup.array().of(
        Yup.object({
          skill: Yup.string().required("Skill is required"),
          experience_in_yrs: Yup.number()
            .required("Experience is required")
            .typeError("Please enter a valid number")
            .integer("Experience must be an integer"),
        })
      ),
    }),
  });

  useEffect(() => {
    setFiles(
      resumeTab.map((item) => ({
        title: item.name,
        progress: 0,
        buffer: 0,
        type: item.file_name,
        name: item.file_name,
        visible: false,
      }))
    );
  }, []);

  const WholeProgressView = () => {
    return (
      <CommonGrid2 marginTop={"1rem"}>
        {files?.map((file, index) => (
          <FileContainer
            key={index}
            elevation={1}
            gap={"1rem"}
            padding={"6px 10px 3px"}
            margin="16px 0px 0px"
            borderRadius="7px"
          >
            <Box display={"flex"} alignItems={"flex-start"} width={"20px"}>
              {getFileIcon(file)}
            </Box>

            <Box width={"100%"}>
              <Typography
                color="#344054"
                fontSize={"14px"}
                fontWeight={700}
                textAlign={"start"}
              >
                {file.title}
              </Typography>

              <Typography color="#475467" fontSize={"14px"} fontWeight={500}>
                {file.size}
              </Typography>
              {file.progress >= 0 && (
                <CommonTippy
                  content={file.message}
                  visible={file.visible}
                  interactive={true}
                  trigger="click"
                  hideOnClick={false}
                  zIndex={2}
                  color="red"
                >
                  <StyledLinearContainer>
                    <CommonLinearProgress
                      variant={
                        file.progress && !file.visible
                          ? "buffer"
                          : "determinate"
                      }
                      value={file.progress}
                      valueBuffer={file.buffer}
                      height={"6px"}
                      left="0px"
                      borderRadius={"2px"}
                      backgroundColor={"#E0E0E0"}
                      barbackgroundColor={file.visible ? "red" : "#7F56D9"}
                      dashedmarginTop={"1px"}
                      dashedbackgroundImage={
                        file.visible
                          ? "radial-gradient(rgb(255 46 0) 0%, rgb(255 0 0) 16%, #00000000 42%)"
                          : "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)"
                      }
                    />

                    <Typography
                      color="#344054"
                      fontSize={"14px"}
                      fontWeight={500}
                    >
                      {file.progress}%
                    </Typography>
                  </StyledLinearContainer>
                </CommonTippy>
              )}
            </Box>
          </FileContainer>
        ))}
      </CommonGrid2>
    );
  };
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={jobValue}
        validationSchema={validationSchema}
        // validateOnChange={false}
        // validateOnBlur={false}
        // validateOnMount={false}
        onSubmit={async (values, actions) => {
          handleSubmitJob(values, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => {
          setFormikData(values);
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2 container>
                <CommonGrid2
                  size={{ xs: 12, md: 4, sm: 12 }}
                  item
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography variant="h3">
                    {jobData?.job_details?.inferred_title}
                  </Typography>
                </CommonGrid2>
                <CommonGrid2
                  item
                  size={{ xs: 12, md: 8, sm: 12 }}
                  gap="10px"
                  display="flex"
                  justifyContent={"end"}
                >
                  {alignment === "Edit" && !alignItems && (
                    <CommonButton
                      value="Submit"
                      padding="5px 2rem"
                      fontWeight="600"
                      type="submit"
                      disabled={editButtonLoading || isLoading}
                      loading={editButtonLoading}
                      onClick={() => {
                        setTimeout(() => {
                          handleScrollToError(errors);
                        }, 0);
                      }}
                    />
                  )}
                  {alignment !== "Edit" && !alignItems && (
                    <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Rerun Scoring"
                      padding="0px 20px"
                      disabled={isLoading}
                      onClick={() => handleClickRerun()}
                    />
                  )}
                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value="Add Note"
                    padding="0px 20px"
                    onClick={handleClickAddNote}
                    disabled={isLoading}
                  />
                  {alignItems === "Edit" ? (
                    <>
                      <CommonButton
                        variant="contained"
                        color="white"
                        background="#7F56D9"
                        fontWeight="600"
                        padding="5px 20px"
                        value="Next Step"
                      />
                    </>
                  ) : (
                    <>
                      <CommonButton
                        variant="outlined"
                        color="#344054"
                        border="#D0D5DD"
                        fontWeight="600"
                        value="Download"
                        padding="0px 20px"
                        onClick={handleDownloadJdOpen}
                      />
                      <ToogleButton
                        ToogleData={ToogleData}
                        handleChange={handleToogleChange}
                        alignment={alignment}
                        border={"1px solid #D0D5DD"}
                        borderRadius={"8px"}
                        activeColor={"#344054"}
                        color={"#344054"}
                        activeBackgroundColor="#F9FAFB"
                        backgroundColor="#FFFF"
                        disabled={isLoading}
                      />
                    </>
                  )}
                </CommonGrid2>
              </Grid2>
              {(alignment === "View" ||
                (alignment === "Edit" && JobJobEditData.is_scored)) && (
                <Result
                  jobData={
                    alignment === "View" ? jobData?.job_details : JobJobEditData
                  }
                  isLoading={isLoading}
                  fetchJobsList={fetchJobsList}
                  job_id={jobId}
                  toolTipOpen={alignment === "View" ? true : false}
                />
              )}
              {(alignment === "View"
                ? jobData?.job_details?.relevancy_index !== null
                : values["relevancy_index"] !== null) && (
                <Grid2 marginTop={"3rem"}>
                  <RelevancyTable
                    alignment={alignment}
                    isLoading={isLoading}
                    resultHeaderData={resultHeaderData}
                    onDragEnd={onRelevancyDragEnd}
                    dragContent={
                      alignment === "View"
                        ? jobData?.job_details?.relevancy_index
                        : values["relevancy_index"]
                    }
                  />
                </Grid2>
              )}
              {accordionDataView.map((item, index) => (
                <CustomAccordion
                  // borderBottom={"1px solid #EAECF0"}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowUpIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography
                      variant="h4"
                      margin={"15px 0px"}
                      fontWeight={600}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {React.cloneElement(item.component, {
                      formikValues: values,
                      formikErrors: errors,
                      formikTouched: touched,
                      formikHandleChange: handleChange,
                      formikHandleBlur: handleBlur,
                      setFieldValue,
                    })}
                  </AccordionDetails>
                </CustomAccordion>
              ))}

              <AddNewSkillDialog
                open={skillDialog}
                handleClose={handleClose}
                jobModifyData={jobModifyData}
                handleSkillClick={handleSkillClick}
                buttonLoading={buttonLoading}
              />
              <SkillDeleteDialog
                deleteDialog={deleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDeleteSkill={handleDeleteSkill}
                buttonLoading={buttonLoading}
                title="Delete Skill"
                content={dialogData?.data?.skill}
              />

              <SkillDeleteDialog
                deleteDialog={seededDialog.deleteOpen}
                handleDeleteDialogClose={handleSeededClose}
                handleDeleteSkill={handleDeleteSeeded}
                buttonLoading={seededDialog.loading}
                title="Delete Seeded Pre-Screen"
                content={seededDialog.content}
              />
              <AddSeededDialog
                open={seededDialog.addOpen}
                handleDialogClose={handleSeededClose}
                handleSubmit={handleAddSubmit}
                loading={seededDialog.loading}
              />
            </Form>
          );
        }}
      </Formik>

      <NewDeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
        noteLoading={noteList.loading}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <DialogCandidateHub
        open={reScore.rescore}
        disabled={reScore.disabled}
        closeIcon={true}
        handleButttonClose={handleRescoreClose}
        footer={<Footer />}
        icon={""}
        marginTop="-1rem"
        title="Re-Score"
        content={
          <>
            {reScore.noteOpen ? "Do you want to rescore?" : ""}
            {reScore?.file && WholeProgressView()}
          </>
        }
      />

      <CreateNote
        openNoteDialog={noteList.open}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteList.noteId}
        //
        noteUpperData={noteUpperData}
        typeData={typeData}
        noteList={noteList}
        handleNoteSubmit={handleCreateNote}
        loading={noteList.loading}
      />
    </div>
  );
};
export default JobInformation;
