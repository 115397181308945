import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  ButtonGroup,
  debounce,
  Grid,
  List,
  ListItem,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchAndListOfResumes } from "../../api/Api";
import EyeIco from "../../assets/icons/EyeIco";
import FileAddIco from "../../assets/icons/FileAddIco";
import ShareIco from "../../assets/icons/ShareIco";
import CommonButton from "../../component/Button";
import CommonTextField from "../../component/Input";
import CustomMenuItem from "../../component/Menu";
import { POST } from "../../redux/axios/Axios";
import AddUpdateActiveJob from "../common-components/AddUpdateActiveJob";
import AddUpdateNote from "../common-components/AddUpdateNote";
import FilterDialog from "../common-components/FilterDialog";
import {
  CheckBoxSelected,
  DotColor,
  ScoreChange,
  StatusChange,
  StyledFlexRowView,
} from "../common-components/StyledComponents";
import CommonTable from "./Table";

export default function Candidates({ filterState, setFilterState, newState }) {
  console.log({ filterState });
  const [filterPopup, setFilterPopup] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [candidatesList, setCandidatesList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [count, setCount] = useState();
  const [openActiveJobDialog, setOpenActiveJobDialog] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [correctedQuery, setCorrectedQuery] = useState("");
  const [currentQuery, setCurrentQuery] = useState(false);
  const [currentQueryStatus, setCurrentQueryStatus] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const limit = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [tableRowData, setTableRowData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const suggestionsRef = useRef(null);

  const headerData = [
    "ID",
    "Name",
    "Title",
    "Status",
    "RiCE Score",
    "Last Activity",
    "",
  ];
  const sortableColumns = ["ID", "Status", "Score", "Last Activity"];
  const activeJobData = [
    {
      label: "Job",
      data: [
        { id: 1, value: "UX Designer 100001" },
        { id: 2, value: "Product Designer 100002" },
      ],
      onChange: (e) => setTitleValue(e.target.value),
      value: titleValue,
    },
  ];

  const filterOptions = [
    {
      label: "Location",
      key: "location",
      type: "multi-select",
      options: filterList.location,
      value: filterState?.location,
    },
    {
      label: "Domain",
      key: "domain",
      type: "multi-select",
      options: filterList.domain,
      value: filterState?.domain,
    },
    {
      label: "Seniority Level",
      key: "seniority",
      type: "multi-select",
      options: filterList.seniority,
      value: filterState?.seniority,
    },
    { label: "Date Range", key: "dateRange", type: "date" },
    {
      label: "Education",
      key: "education",
      type: "multi-select",
      options: filterList.education,
      value: filterState?.education,
    },
  ];

  const fetchCandidatesList = async ({
    limit = 10,
    offset = (currentPage - 1) * limit,
    q = debouncedQuery,
    filterValues = {},
    status_flag,
  }) => {
    console.log(filterValues);
    setLoading(true);
    setCurrentQueryStatus(false);
    try {
      const filters = [
        {
          field: "personal_details.location.keyword",
          value: filterValues.location || "",
        },
        {
          field: "personal_details.industry.keyword",
          value: filterValues.domain || "",
        },
        {
          field: "personal_details.seniority_level.keyword",
          value: filterValues.seniority || "",
        },
        {
          field: "updated_at",
          from_date: filterValues.dateRange?.from_date || "",
          to_date: filterValues.dateRange?.to_date || "",
        },
        {
          field: "elc_table.qualifications.name.keyword",
          value: filterValues.education || "",
        },
      ].filter((filter) => Object.values(filter).some((value) => value !== ""));

      const response = await POST(SearchAndListOfResumes(limit, offset), {
        q,
        filter: filters,
        status_flag,
      });

      const data = response.data?.data;
      if (data?.results) {
        setCandidatesList(data.results);
        setTotalPage(data.total_pages || "");
        setCount(data?.count || "");
        setFilterList(data?.filters);
        setSuggestion(data?.suggestions);
        setCorrectedQuery(data?.corrected_query);
        setCurrentQuery(data?.current_query);
        setCurrentQueryStatus(true);
      } else {
        setCandidatesList([]);
        setFilterList([]);
        setSuggestion([]);
        setTotalPage(0);
        setCount(0);
      }
    } catch (err) {
      console.error("Failed to fetch resumes:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = debounce((query) => {
    setDebouncedQuery(query);
    setShowSuggestion(query.length > 0);
  }, 500);

  const handleSearchInput = (e) => {
    // const query = e.target.value.trim();
    const query = e.target.value;
    setTitleValue(query);
    handleSearchChange(query);
    setShowSuggestion(query.length > 0);
  };
  console.log("Current Query:", titleValue);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowSuggestion(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCurrentQueryStatus(false);
    fetchCandidatesList({
      limit,
      offset: (currentPage - 1) * limit,
      q: debouncedQuery,
      // filterValues:  || {},
      status_flag: currentQueryStatus,
    });
  }, [debouncedQuery, currentPage]);

  const handleRowCheckboxChange = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setFilterPopup(false);
  };

  const handleAddNote = () => {
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  const handleOpenActiveJobDialog = () => {
    setOpenActiveJobDialog(true);
    handleMenuClose();
  };

  const handleCloseActiveJobDialog = () => {
    setOpenActiveJobDialog(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setTitleValue(suggestion);
    setDebouncedQuery(suggestion);
    setShowSuggestion(false);
  };
  const [noteId, setNoteId] = useState("");
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);

  const menuData = [
    {
      title: "Share",
      image: <ShareIco />,
    },
    {
      title: "Add to an Active Job",
      image: <FileAddIco />,
      disabled: true,
      onClick: handleOpenActiveJobDialog,
    },
    {
      title: "View",
      image: <EyeIco />,
      onClick: () =>
        navigate(
          `/candidates/candidate-view?view-id=${tableRowData.resume_id}`
        ),
    },
  ];

  const handleIconClick = (event, rowData) => {
    setTableRowData(rowData);
    setAnchorEl(event.currentTarget);
  };
  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const handleClick = () => {
    if (currentQueryStatus) {
      setCurrentQueryStatus(true);
      fetchCandidatesList({
        limit,
        offset: (currentPage - 1) * limit,
        q: currentQuery,
        filterValues: filterState || {},
        status_flag: currentQueryStatus,
      });
      setShowSuggestion(false);
    }
  };

  const handleTableBodyData = (row) => {
    return (
      <>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.job_title}</TableCell>
        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.status === "Inactive" || row.status === "Closed"
                  ? "#667085"
                  : row.status === "Under Consideration" ||
                    row.status === "Hold"
                  ? "orange"
                  : row.status === "Available" || row.status === "Active"
                  ? "green"
                  : "#667085"
              }
            />
            {row.status}
          </StatusChange>
        </TableCell>
        <TableCell>
          <ScoreChange>{row.rice_score}%</ScoreChange>
        </TableCell>
        <TableCell>{row.updated_at}</TableCell>
        <TableCell>
          <MoreVertOutlinedIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => handleIconClick(e, row)}
          />
          <CustomMenuItem
            open={open}
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            menuData={menuData}
            boxShadow={
              "0px 0px 0px 0px rgba(16, 24, 40, 0.08),0px 3px 5px -3px rgba(16, 24, 40, 0.03)"
            }
          />
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={5} display="flex" alignItems="center">
          <Typography variant="h2">Candidates</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          alignItems="center"
          sx={{ gap: 2, position: "relative" }}
        >
          <CommonTextField
            placeholder="Search"
            startIcon={<SearchIcon />}
            value={titleValue}
            onChange={handleSearchInput}
          />
          {showSuggestion && suggestion.length > 0 && (
            <List
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                background: "white",
                border: "1px solid #ccc",
                borderRadius: 1,
                zIndex: 1000,
                maxHeight: 200,
                overflowY: "auto",
                fontFamily: "Inter",
              }}
            >
              {suggestion.map((sug, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleSuggestionClick(sug)}
                  sx={{
                    padding: "8px 16px",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  {sug}
                </ListItem>
              ))}
            </List>
          )}
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Filters"
            onClick={() => setFilterPopup(true)}
          />
          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add New"
            onClick={() =>
              navigate("/reusme-intelligence/create-candidate-resume")
            }
          />
        </Grid>
      </Grid>
      <StyledFlexRowView
        style={{ justifyContent: "space-between", padding: "32px 0px 0px 0px" }}
      >
        <CheckBoxSelected>
          Selected{" "}
          <span style={{ color: "#667085" }}> · {selectedRows.length}</span>
        </CheckBoxSelected>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Add to Job Pool"
            padding={"10px 16px"}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Re-run RiCE Scoring"
            padding={"10px 16px"}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Compare"
            padding={"10px 16px"}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Schedule Interview"
            padding={"10px 16px"}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Change Status"
            padding={"10px 16px"}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Add Notes"
            onClick={handleAddNote}
            padding={"10px 16px"}
          />
        </ButtonGroup>
      </StyledFlexRowView>

      {correctedQuery && (
        <CheckBoxSelected style={{ padding: "16px 0px 0px 0px" }}>
          <span style={{ color: "#000" }}>{"These are results for "}</span>
          <span style={{ color: "#7f56d9" }}>{correctedQuery}</span>
        </CheckBoxSelected>
      )}
      {currentQuery && correctedQuery !== null && (
        <CheckBoxSelected
          style={{ padding: "0px 0px 0px 0px", fontSize: "14px" }}
        >
          <span style={{ color: "#000" }}>{"Search instead for "}</span>
          <button
            style={{
              color: "#7f56d9",
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "inherit",
              textDecoration: "underline",
            }}
            onClick={handleClick}
          >
            {currentQuery}
          </button>
        </CheckBoxSelected>
      )}

      <CommonTable
        data={candidatesList}
        columns={headerData}
        sortedColumns={sortableColumns}
        onSortChange={(col) => console.log("Sort by", col)}
        onRowCheckboxChange={handleRowCheckboxChange}
        handleTableBodyData={handleTableBodyData}
        paginationProps={{
          count: totalPage,
          page: currentPage,
          onPageChange: (page) => setCurrentPage(page),
        }}
        withPagination={true}
        withCheckbox={true}
      />
      <FilterDialog
        open={filterPopup}
        onClose={handleClose}
        title="Filter"
        filterOptions={filterOptions}
        onApply={(filterValues) =>
          fetchCandidatesList({ filterValues }).finally(() => {
            handleClose();
          })
        }
        backgroundImage="path_to_background_image"
        count={count}
        limit={limit}
        currentPage={currentPage}
        debouncedQuery={debouncedQuery}
        currentQueryStatus={currentQueryStatus}
        fetchCandidatesList={fetchCandidatesList}
        setFilterState={setFilterState}
      />

      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={handleCloseNoteDialog}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
      />
      <AddUpdateActiveJob
        open={openActiveJobDialog}
        onClose={handleCloseActiveJobDialog}
        activeOptions={activeJobData}
        onApply={() => {
          handleClose();
        }}
        backgroundImage="path_to_background_image"
      />
    </>
  );
}
