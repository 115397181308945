import {
  Dialog,
  DialogContent,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import CommonButton from "../../../../component/Button";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import FileUpload from "./FileUpload";

function AddMoreCandidate({
  addMoreCandidateDialogOpen,
  handleAddMoreCandidateDialogClose,
  handleAddMoreCandidateDragOver,
  handleAddMoreCandidateDragLeave,
  handleAddMoreCandidateDrop,
  handleAddMoreCandidateFileUpload,
  addMoreCandidateFiles,
  isAddMoreCandidateDragging,
  handleAddMoreCandidateDeleteFile,
  handleAddMoreCandidateClick,
  isLoading,
  isJem,
  disabled = false,
  buttonDisabled,
}) {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={addMoreCandidateDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          width: "500px",
          maxWidth: "none",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <DialogContent>
        <Grid2 conatiner spacing={4}>
          <CommonGrid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"row"}
            gap={"10px"}
            alignItems={"center"}
            marginBottom={"10px"}
          >
            <Typography fontSize={"18px"} color="#101828" fontWeight={600}>
              {"Add More Candidates"}
            </Typography>
            <IconButton onClick={handleAddMoreCandidateDialogClose}>
              {<XCloseIco />}
            </IconButton>
          </CommonGrid2>

          <FileUpload
            onDragOver={handleAddMoreCandidateDragOver}
            onDragLeave={handleAddMoreCandidateDragLeave}
            onDrop={handleAddMoreCandidateDrop}
            onChange={handleAddMoreCandidateFileUpload}
            files={addMoreCandidateFiles}
            isDragging={isAddMoreCandidateDragging}
            handleDeleteFile={handleAddMoreCandidateDeleteFile}
            disabled={isLoading || disabled}
            isJem={isJem}
          />
          <CommonButton
            variant="contained"
            color="white"
            width={"100%"}
            background="#7F56D9"
            fontWeight="600"
            value={isJem ? "Submit" : "Add New"}
            padding={"10px 0"}
            disabled={buttonDisabled}
            onClick={() => handleAddMoreCandidateClick()}
            loading={isLoading}
          />
        </Grid2>
      </DialogContent>
    </Dialog>
  );
}

export default AddMoreCandidate;
