import { Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewCandidateProfile from "../../resume-intelligence/create-candidate-resume/components/ReviewCandidateProfile";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CandidateViewCall } from "../../../redux/action/Candidate";
import SnackBar from "../../../component/SnackBar";
import { ResumeIntelligenceFix } from "../../../redux/action/ResumeIntelligence";
import { ChatBotRelative } from "../../jobs/style";
import ChatBot from "../../chat-bot";
import { GETDOWNLOAD } from "../../../redux/axios/Axios";
import { DownloadResume } from "../../../api/Api";

const CandidateView = () => {
  const [fixLoading, setFixLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [iconVisible, setIconVisible] = React.useState(true);
  const query = new URLSearchParams(useLocation().search);
  const viewId = query.get("view-id");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [resumeData, setResumeData] = useState({});

  const handleCandidateViewCall = async () => {
    setIsLoading(true);
    try {
      const data = await dispatch(CandidateViewCall(viewId));
      setResumeData(data);
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleCandidateViewCall();
  }, []);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleScoreFix = async () => {
    setFixLoading(true);
    try {
      const data = await ResumeIntelligenceFix(viewId);
      setFixLoading(false);
      setIsLoading(true);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      const fixData = await dispatch(CandidateViewCall(viewId));
      setResumeData(fixData);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setFixLoading(false);
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleChatBotClick = (value) => {
    setIconVisible(value);
  };

  const handleDownloadResume = () => {
    if (resumeData?.id) {
      GETDOWNLOAD(DownloadResume(resumeData.id), {
        params: { type: "resume" },
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const fileName = `${resumeData?.resume_file_name}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              severity: "error",
              message: "An unexpected error occurred.",
            });
          }
        });
    } else {
      setSnackbarState({
        open: true,
        severity: "error",
        message: "Resume was not parsed successfully. Unable to download.",
      });
    }
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography fontSize={"24px"} fontWeight={600} marginBottom={"2rem"}>
          {"Resume Intelligence Engine Module"}
        </Typography>
      </Grid2>
      <ReviewCandidateProfile
        resumeData={resumeData}
        isLoading={isLoading}
        fixLoading={fixLoading}
        handleScoreFix={handleScoreFix}
        handleDownloadResume={handleDownloadResume}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <ChatBotRelative>
        <ChatBot
          resumeIntelligence={true}
          jobId={viewId}
          iconVisible={iconVisible}
          handleClick={handleChatBotClick}
        />
      </ChatBotRelative>
    </Grid2>
  );
};
export default CandidateView;
