import { Box, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";
import FileUpload from "./FileUpload";

const UploadJobInformation = ({
  handleDragOver,
  handleDragLeave,
  handleDrop,
  handleFileUpload,
  isDragging,
  files,

  handleDeleteFile,
  handleNextUploadClick,
  handleJobTitle,
  jobTitle,
  isLoading,
  tabData,
  buttonDisabled,
}) => {
  return (
    <Box width={"600px"} margin={"auto"}>
      <Typography
        fontSize={"16px"}
        fontWeight={500}
        marginBottom={2}
        marginTop={3}
      >
        Job Description
      </Typography>
      <FileUpload
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onChange={handleFileUpload}
        isDragging={isDragging}
        files={files}
        handleDeleteFile={handleDeleteFile}
        disabled={isLoading}
      />

      <CommonButton
        width={"100%"}
        variant="contained"
        color="white"
        background="#7F56D9"
        fontWeight="600"
        value="Next"
        padding={"10px 0px"}
        onClick={handleNextUploadClick}
        disabled={tabData[1].disabled === false || buttonDisabled}
        loading={tabData[1].disabled === false || isLoading}
      />
    </Box>
  );
};
export default UploadJobInformation;
