function TalairoLogo({ color, width = "100%", height = "auto", marginLeft = "0" }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 214 55"
            fill="none"
            style={{ marginLeft }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2.5 37C28.5 56.5 44.6869 35.9464 45.1869 26.4464L35.6869 25.9464C33.2869 37.9464 15.5 39 8.5 30.5L2.5 37Z" fill="url(#paint0_linear_645_19043)" />
            <circle cx="25.1869" cy="19.9464" r="10.5" fill="url(#paint1_radial_645_19043)" />
            <path d="M28.1868 0.446337C33.4114 1.39626 34.6647 4.39056 34.3323 7.39936C33.8975 11.3363 30.5802 14.3936 26.7678 15.4677C26.2263 15.6202 25.7066 15.7386 25.2143 15.8285C22.563 16.3125 19.8106 15.8178 17.1302 16.0997C2.75985 17.6112 2.69263 29.3103 4.68684 35.4464C1.34395 32.9392 0.232165 27.6997 0.0341084 23.3423C-0.114111 20.0813 0.522826 16.8104 1.89254 13.8473C7.34206 2.05856 18.3627 -1.33987 28.1868 0.446337Z" fill="url(#paint2_linear_645_19043)" />
            <path d="M15.6869 25.4464C33.2869 29.0464 39.6869 16.2797 40.6869 9.44635C49.0869 18.6463 45.1869 29.9464 42.1869 34.4464C39.6869 30.9464 35.1869 27.4464 29.6869 30.4464C26.8123 32.0143 23.7813 33.8099 21.5139 35.1917C19.6395 36.3341 17.5556 37.2428 15.3688 37.0524C12.3214 36.7872 8.97196 35.4373 8.68689 31.4464C8.28689 25.8463 13.1869 25.113 15.6869 25.4464Z" fill="url(#paint3_linear_645_19043)" />
            <path d="M70.665 34V17.26H64.527V14.036H80.957V17.26H74.85V34H70.665ZM94.8913 34L102.517 14.036H107.105L114.7 34H110.36L108.469 29.195H101.122L99.2623 34H94.8913ZM101.742 25.971H107.849L104.78 17.942L101.742 25.971ZM132.491 34V14.036H136.676V30.776H146.503V34H132.491ZM163.068 34L170.694 14.036H175.282L182.877 34H178.537L176.646 29.195H169.299L167.439 34H163.068ZM169.919 25.971H176.026L172.957 17.942L169.919 25.971ZM200.668 34V14.036H204.853V34H200.668ZM223.268 34V14.036H232.847C235.141 14.036 236.825 14.6457 237.9 15.865C238.974 17.0637 239.512 18.686 239.512 20.732C239.512 22.0753 239.16 23.2327 238.458 24.204C237.776 25.1753 236.804 25.878 235.544 26.312C235.874 26.5807 236.133 26.87 236.319 27.18C236.525 27.49 236.732 27.9033 236.939 28.42L239.357 34H235.017L232.661 28.637C232.454 28.1823 232.227 27.862 231.979 27.676C231.731 27.49 231.317 27.397 230.739 27.397H227.453V34H223.268ZM227.453 23.925H231.762C232.857 23.925 233.715 23.6873 234.335 23.212C234.955 22.716 235.265 21.8893 235.265 20.732C235.265 19.616 234.996 18.7997 234.459 18.283C233.921 17.7663 233.105 17.508 232.01 17.508H227.453V23.925ZM265.94 34.31C259.471 34.31 256.237 30.9103 256.237 24.111C256.237 20.453 257.053 17.8077 258.686 16.175C260.318 14.5423 262.736 13.726 265.94 13.726C269.143 13.726 271.561 14.5423 273.194 16.175C274.826 17.8077 275.643 20.453 275.643 24.111C275.643 30.9103 272.408 34.31 265.94 34.31ZM265.94 30.745C267.82 30.745 269.195 30.2077 270.063 29.133C270.951 28.0377 271.396 26.3637 271.396 24.111C271.396 21.6103 270.951 19.8537 270.063 18.841C269.195 17.8077 267.82 17.291 265.94 17.291C264.059 17.291 262.674 17.8077 261.786 18.841C260.918 19.8537 260.484 21.6103 260.484 24.111C260.484 26.3637 260.918 28.0377 261.786 29.133C262.674 30.2077 264.059 30.745 265.94 30.745Z" fill="url(#paint4_linear_645_19043)" />
            <defs>
                <linearGradient id="paint0_linear_645_19043" x1="34.5" y1="41.5" x2="17" y2="27.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7337A5" />
                    <stop offset="0.5" stopColor="#5B2E80" />
                    <stop offset="1" stopColor="#42245A" stopOpacity="0" />
                </linearGradient>
                <radialGradient id="paint1_radial_645_19043" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.1869 19.9464) rotate(90) scale(10.5)">
                    <stop stopColor="#8C45CA" />
                    <stop offset="1" stopColor="#3D1C5B" />
                </radialGradient>
                <linearGradient id="paint2_linear_645_19043" x1="34.3821" y1="17.7232" x2="0" y2="17.7232" gradientUnits="userSpaceOnUse">
                    <stop offset="0.364" stopColor="#C59FE8" />
                    <stop offset="0.794" stopColor="#873FC5" />
                    <stop offset="1" stopColor="#5C347F" />
                </linearGradient>
                <linearGradient id="paint3_linear_645_19043" x1="45.7575" y1="23.2754" x2="8.66382" y2="23.2754" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5C347F" />
                    <stop offset="0.199079" stopColor="#8435C8" />
                    <stop offset="0.724" stopColor="#C59FE8" />
                </linearGradient>
                <linearGradient id="paint4_linear_645_19043" x1="170.5" y1="13" x2="170.5" y2="33" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B895D6" />
                    <stop offset="1" stopColor="#6B4091" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default TalairoLogo;
