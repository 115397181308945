import { Skeleton, Switch, Typography } from "@mui/material";
import { Field } from "formik";
import React from "react";
import { AutocompleteText } from "../../../component/AutoComplete";
import CommonTextField from "../../../component/Input";
import DropDownSelect from "../../../component/Select";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { ErrorContent } from "../style";
import DateRangePickerComp from "./DatePicker";

const EditIndex = ({
  editData,
  menuItemData,
  type,
  loading,
  rowSize = false,
  marginTop,
}) => {
  return (
    <CommonGrid2
      container
      columnSpacing={3}
      rowSpacing={loading ? 0 : 3}
      marginTop={marginTop}
    >
      {editData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: item.type === "additional" || rowSize ? 6 : 4,
              sm: 6,
            }}
            key={index + item.title}
            gap={"5px"}
            display={"flex"}
            flexDirection={item.component === "switch" ? "row" : "column"}
            justifyContent={"flex-start"}
          >
            {loading ? (
              <Skeleton height={100} />
            ) : (
              <>
                <CommonGrid2
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography variant="p" color="#344054" fontSize="16px">
                    {item.title}
                  </Typography>
                  {item.type === "additional" && (
                    <Field name={item.toggleName}>
                      {({ field, form }) => {
                        const toggleValue =
                          form.values[item.toggleName] || false;
                        return (
                          <Switch
                            checked={toggleValue}
                            onChange={(event) => {
                              form.setFieldValue(
                                item.toggleName,
                                event.target.checked
                              );
                            }}
                          />
                        );
                      }}
                    </Field>
                  )}
                </CommonGrid2>
                {item.component === "input" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item.title}
                          padding="10px"
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          onChange={(e) =>
                            form.setFieldValue(item.titleName, e.target.value)
                          }
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "input-select" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleDropdownChange = (
                        event,
                        name,
                        currencyValue
                      ) => {
                        const exchangeRate = 83;
                        function convertUsdToInr(usdValue) {
                          return usdValue * exchangeRate;
                        }

                        function convertInrToUsd(inrValue) {
                          return inrValue / exchangeRate;
                        }

                        const updatedPayRate = {
                          ...form.values[item.titleName],
                          [currencyValue]: event.target.value,
                          min:
                            event.target.value === "INR"
                              ? convertUsdToInr(
                                  form.values[item.titleName]["min"]
                                )
                              : convertInrToUsd(
                                  form.values[item.titleName]["min"]
                                ),
                          max:
                            event.target.value === "INR"
                              ? convertUsdToInr(
                                  form.values[item.titleName]["max"]
                                )
                              : convertInrToUsd(
                                  form.values[item.titleName]["max"]
                                ),
                        };
                        form.setFieldValue(name, updatedPayRate);
                      };

                      const handleChange = (event, name, currency) => {
                        const exchangeRate = 83;

                        const updatedPayRate = {
                          ...form.values[item.titleName],
                          [currency]: event.target.value,
                        };

                        form.setFieldValue(name, updatedPayRate);
                      };

                      return (
                        <CommonTextField
                          showSelect={true}
                          placeholder={"Enter the rate"}
                          menuItemData={menuItemData}
                          onChange={(e) =>
                            handleChange(e, item.titleName, item.currency)
                          }
                          currency={
                            form.values[item.titleName][item.currencyValue]
                          }
                          padding="10px"
                          handleCurrencyChange={(e) =>
                            handleDropdownChange(
                              e,
                              item.titleName,
                              item.currencyValue
                            )
                          }
                          error={
                            form.touched[item.titleName]?.[item.currency] &&
                            form.errors[item.titleName]?.[item.currency]
                          }
                          helperText={
                            form.touched[item.titleName]?.[item.currency] &&
                            form.errors[item.titleName]?.[item.currency]
                          }
                          value={form.values[item.titleName][item.currency]}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "dropDown" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleDropdownChange = (event) => {
                        form.setFieldValue(item.titleName, event.target.value);
                      };

                      return (
                        <DropDownSelect
                          marginTop="0px"
                          title={item.title}
                          removeAnimation={true}
                          data={form.values[item.dropDownData]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          handleChange={handleDropdownChange}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "calender" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError =
                        form.touched[item.titleName]?.start_date &&
                        form.touched[item.titleName]?.end_date &&
                        Boolean(form.errors["Date_Range"]);

                      const helperText = isError
                        ? form?.errors["Date_Range"]
                        : "";

                      return (
                        <>
                          <DateRangePickerComp
                            startDateProp={form.values.Date_Range?.start_date}
                            endDateProp={form.values.Date_Range?.end_date}
                            handleChange={(start_date, end_date) => {
                              form.setFieldValue(item.titleName, {
                                start_date: start_date,
                                end_date: end_date,
                              });
                            }}
                            error={isError}
                          />
                          {isError && <ErrorContent>{helperText}</ErrorContent>}
                        </>
                      );
                    }}
                  </Field>
                )}

                {item.component === "autoComplete" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleAutoCompleteChange = (e, newValue) => {
                        const checkData = form?.values[item.titleName];
                        if (e?.key === "Backspace" && e.target.value === "") {
                          const updatedValue = [
                            ...(form.values[item.titleName] || []),
                          ];
                          updatedValue.pop();
                          form.setFieldValue(item.titleName, updatedValue);
                        } else {
                          const formattedValues = newValue.map(
                            (item, index) => {
                              if (typeof item === "string") {
                                return {
                                  content: item,
                                  ...(checkData[0]?.name
                                    ? { name: "inferred" }
                                    : { id: index }),
                                };
                              }
                              return item;
                            }
                          );

                          form.setFieldValue(item.titleName, formattedValues);
                        }
                      };

                      const handleDelete = (option) => {
                        const updatedValue = form.values[item.titleName].filter(
                          (item, i) => item !== option
                        );
                        form.setFieldValue(item.titleName, updatedValue);
                      };

                      const isError =
                        form.touched[item.titleName] &&
                        form.errors[item.titleName];
                      const helperText = isError
                        ? form.errors[item.titleName]
                        : "";

                      const handleKeyDown = (e) => {
                        const checkData = form?.values[item.titleName];
                        const dataValue = e.target.value;
                        form.setFieldValue(item.inputValue, dataValue);
                        if (e.key === "Tab") {
                          e.preventDefault();

                          if (dataValue !== "") {
                            const data = {
                              content: dataValue,
                              ...(checkData[0]?.name
                                ? { name: "inferred" }
                                : { id: index }),
                            };

                            const formattedValues = [...checkData, data];
                            form.setFieldValue(item.titleName, formattedValues);
                            form.setFieldValue(item.inputValue, "");
                          }
                        }
                      };

                      const onInputChange = (e, newValue) => {
                        form.setFieldValue(item.inputValue, newValue);
                      };

                      return (
                        <>
                          <AutocompleteText
                            autoData={{
                              options: [],
                              key: `autocomplete-${index}`,
                              title: "Select Emails",
                              placeholder: item.placeholder,
                              isDisabled: false,
                              value: form.values[item.titleName] || "",
                              onDelete: handleDelete,
                              onKeyDown: handleKeyDown,
                              onChange: handleAutoCompleteChange,
                              inputValue: form.values[item.inputValue] || "",
                              onInputChange: onInputChange,
                            }}
                            error={isError}
                          />
                          {isError && <ErrorContent>{helperText}</ErrorContent>}
                        </>
                      );
                    }}
                  </Field>
                )}

                {item.component === "input-list" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item.title}
                          padding="10px"
                          error={Boolean(
                            form.touched[item.titleName]?.[index] &&
                              form.errors[item.titleName]?.[index]
                          )}
                          helperText={
                            form.touched[item.titleName]?.[index] &&
                            form.errors[item.titleName]?.[index]
                          }
                          value={form.values[item.titleName]?.[index] || ""}
                          onChange={(e) => {
                            const updatedValues = [
                              ...form.values[item.titleName],
                            ];
                            updatedValues[index] = e.target.value;
                            form.setFieldValue(item.titleName, updatedValues);
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
              </>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default EditIndex;
