import { styled } from "@mui/material";

export const ChatBotFooter = styled("div")(() => ({
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "16px",
  color: "rgba(0, 0, 0, 0.6)",
}));

export const WholePageOpacity = styled("div")(() => ({
  width: "100%",
  height: "100%",
  opacity: 0.6,
  top: "0%",
  background: "rgb(245 253 255)",
  zIndex: 9999,
  borderRadius: "10px",
  position: "absolute",
}));
export const WholePageEllipse = styled("div")(() => ({
  zIndex: 99999,
  position: "absolute",
  left: "40%",
  top: "42%",
}));

export const InnerContainer = styled("div")(() => ({
  background: "#fff",
  padding: "1rem 0px 5px",
  right: "10px",
  // border: "1px solid",
  position: "relative",
  width: "380px",
  borderRadius: "10px",
  boxShadow: "1px 1px 5px 0px rgba(16, 24, 40, 0.05)",
  background: "#D1EFFF",
}));
