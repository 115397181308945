import { URL } from "../../api/Api";
import { catchError, POST } from "../axios/Axios";

export const LoginCall = async (payload) => {
  try {
    const response = await POST(`${URL}/token/`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
