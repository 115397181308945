import { TabContext } from "@mui/lab";
import { Box, Grid2, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileExtensionIco from "../../../assets/icons/FileExtensionIco";
import SnackBar from "../../../component/SnackBar";
import {
  ReviewMatchInformation,
  UploadJob,
} from "../../../redux/action/QuickMatch";
import {
  CreateTabList,
  TabPanelView,
} from "../../common-components/StyledComponents";
import ErrorDialog from "./components/ErrorDialog";
import ReviewMatch from "./components/ReviewMatch";
import UploadCandidate from "./components/UploadCandidate";
import UploadJobInformation from "./components/UploadJobInformation";
import SkillDeleteDialog from "../../jobs/job-information/skills/SkillDeleteDialog";
import { JobJobDelete } from "../../../redux/action/Job";

function QuickMatch() {
  const [value, setValue] = useState(0);
  const [files, setFiles] = useState([]);
  const [candidateFiles, setCandidateFiles] = useState([]);
  const [addMoreCandidateFiles, setAddMoreCandidateFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isCandidateDragging, setIsCandidateDragging] = useState(false);
  const [isAddMoreCandidateDragging, setIsAddMoreCandidateDragging] =
    useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [jobTitle, setJobTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [uploadJobData, setUploadJobData] = useState({});
  const [uploadJobDataList, setUploadJobDataList] = useState([]);
  const dispatch = useDispatch();
  const reviewMatchData = useSelector(
    (state) => state.quickMatchState.reviewMatch
  );
  const [addMoreCandidateDialogOpen, setAddMoreCandidateDialogOpen] =
    useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [wrongClose, setWrongClose] = useState(false);
  const navigate = useNavigate();
  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const [deleteResume, setDeleteResume] = useState(false);
  const [resumeRow, setResumeRow] = useState({});
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const uploadFile = async (getName, setCandidateFiles, getAddMore) => {
    setIsLoading(true);
    setButtonDisabled(true);
    if (getName === "NextUpload") {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          delete: false,
        }))
      );
    } else {
      setCandidateFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          delete: false,
        }))
      );
    }
    const formData = new FormData();
    if (getName === "NextUpload") {
      files.forEach((fileObj, index) => {
        formData.append("files", fileObj.file);
      });
    } else if (getAddMore === "Add-more-Candidate") {
      addMoreCandidateFiles.forEach((fileObj, index) => {
        formData.append("resume_file", fileObj.file);
      });
      formData.append("id", uploadJobData?.data?.id);
    } else {
      candidateFiles.forEach((fileObj, index) => {
        formData.append("resume_file", fileObj.file);
      });
      formData.append("id", uploadJobData?.data?.id);
    }
    try {
      const response = await UploadJob(formData, getName, "Active");
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(
              jsonResponse,
              getName,
              setCandidateFiles,
              getAddMore
            );
          } catch (e) {
            if (getName === "NextUpload") {
              setFiles((prevFiles) =>
                prevFiles.map((f) => ({
                  ...f,
                  visible: true,
                  delete: true,
                  message: jsonResponse.message,
                }))
              );
              setWrongClose(true);
            } else {
              setSnackbarState({
                open: true,
                message: "Error parsing JSON",
                severity: "error",
              });
              setCandidateFiles((prevFiles) =>
                prevFiles.map((f) =>
                  f.name === jsonResponse.data.current_resume
                    ? {
                        ...f,
                        visible: true,
                        delete: true,
                      }
                    : { ...f, delete: true }
                )
              );
            }
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(
            jsonResponse,
            getName,
            setCandidateFiles,
            getAddMore
          );
        } catch (e) {
          if (getName === "NextUpload") {
            setFiles((prevFiles) =>
              prevFiles.map((f) => ({
                ...f,
                visible: true,
                delete: true,
                message: jsonResponse.message,
              }))
            );
            setWrongClose(true);
          } else {
            setSnackbarState({
              open: true,
              message: "Error parsing final JSON chunk",
              severity: "error",
            });
            setCandidateFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.name === jsonResponse.data.current_resume
                  ? {
                      ...f,
                      visible: true,
                      delete: true,
                    }
                  : { ...f, delete: true }
              )
            );
          }
        }
      }
      setIsLoading(false);
      setButtonDisabled(false);
    } catch (error) {
      if (getName === "NextUpload") {
        setFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: "Error during the request",
          }))
        );

        setWrongClose(true);
      } else {
        setSnackbarState({
          open: true,
          message: "Error during the request",
          severity: "error",
        });
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: "Error during the request",
          }))
        );
      }
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  const processJsonResponse = (
    jsonResponse,
    getName,
    setCandidateFiles,
    getAddMore
  ) => {
    const { success, data, message } = jsonResponse;
    setUploadJobDataList(data);
    if (success && data?.percent !== undefined) {
      if (getName === "NextUpload") {
        setFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            progress: data.percent,
            buffer: data.buffer || data.percent,
            visible: false,
            delete: false,
          }))
        );
      } else {
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.name === data.current_resume
              ? {
                  ...f,
                  progress: data.percent,
                  buffer: data.buffer || data.percent,
                  visible: false,
                  delete: false,
                }
              : f
          )
        );
      }
    } else if (data?.id && getName === "NextUpload") {
      setUploadJobData(jsonResponse);
      setSnackbarState({
        open: true,
        message: message,
        severity: "success",
      });

      setTabData((prev) =>
        prev.map((tab, index) => (index < 1 ? { ...tab, disabled: true } : tab))
      );
      setValue(1);
    } else if (getAddMore === "Add-more-Candidate" && data?.length !== 0) {
      setAddMoreCandidateDialogOpen(false);
      setAddMoreCandidateFiles([]);
      handleReviewMatchInformation();
    } else if (data?.length !== 0 && getName === "Upload-Candidate") {
      setSnackbarState({
        open: true,
        message: message,
        severity: "success",
      });
      setTabData((prev) =>
        prev.map((tab, index) =>
          index <= 1 ? { ...tab, disabled: true } : tab
        )
      );
      setValue(2);
    } else if (!success) {
      if (getName === "NextUpload") {
        setFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: message,
          }))
        );
        setWrongClose(true);
      } else {
        setWrongClose(true);
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.name === data.current_resume || data.length == 0
              ? {
                  ...f,
                  visible: true,
                  delete: true,
                  message: message,
                }
              : { ...f, delete: true }
          )
        );
      }
    }
  };

  const commonFileValidationCheck = (
    commonFiles,
    allowedTypes,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = [];
    const invalidFiles = [];

    commonFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push({
          file,
          name: file.name,
          size: `${(file.size / 1024).toFixed(1)} KB`,
          type: file.type,
          progress: 0,
          buffer: 0,
          visible: false,
          delete: true,
          message: "",
        });
      } else {
        invalidFiles.push(file.name);
      }
    });

    const mergedFiles = [
      ...existingFiles,
      ...validFiles.filter(
        (newFile) =>
          !existingFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      ),
    ];
    setCommonFiles(mergedFiles);

    if (invalidFiles.length > 0) {
      setSnackbarState({
        open: true,
        message: `Unsupported file types: ${invalidFiles.join(", ")}`,
        severity: "error",
      });
    }
  };
  // Handle file upload through input
  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(selectedFiles, allowedTypes, setFiles, files);
  };

  // Handle file deletion
  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Drag-and-drop functionality
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(droppedFiles, allowedTypes, setFiles, files);
  };

  const handleCandidateFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(
      selectedFiles,
      allowedTypes,
      setCandidateFiles,
      candidateFiles
    );
  };

  const handleCandidateDeleteFile = (index) => {
    setCandidateFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (files.length === 0 && value === 0) {
      setTabData((prev) =>
        prev.map((tab, index) => ({ ...tab, disabled: true }))
      );
      setIsLoading(false);
      setButtonDisabled(false);
    }
    if (candidateFiles.length === 0 && value === 1) {
      setTabData((prev) =>
        prev.map((tab, index) =>
          index === 2 ? { ...tab, disabled: true } : tab
        )
      );
      setIsLoading(false);
      setButtonDisabled(false);
    }
    if (addMoreCandidateFiles.length === 0 && value === 2) {
      setIsLoading(false);
      setButtonDisabled(false);
    }
    if (files.length !== 0 && value === 0) {
      const getData = files.some((item) => item.visible);
      if (getData) {
        setIsLoading(false);
        setButtonDisabled(true);
      }
    } else if (
      (candidateFiles.length !== 0 && value === 1) ||
      (addMoreCandidateFiles.length !== 0 && value === 2)
    ) {
      const getData = (
        value === 1 ? candidateFiles : addMoreCandidateFiles
      ).every((item) => item.visible);

      if (getData) {
        setIsLoading(false);
        setButtonDisabled(true);
      }
    }
  }, [files, candidateFiles, addMoreCandidateFiles]);

  const handleReviewMatchInformation = async () => {
    try {
      setTableLoader(true);
      await dispatch(ReviewMatchInformation(uploadJobData.data.id));

      setTableLoader(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setTableLoader(false);
    }
  };
  useEffect(() => {
    if (value === 2) {
      handleReviewMatchInformation();
    }
  }, [value]);

  const handleCandidateDragOver = (event) => {
    event.preventDefault();
    setIsCandidateDragging(true);
  };

  const handleCandidateDragLeave = () => {
    setIsCandidateDragging(false);
  };

  const handleCandidateDrop = (event) => {
    event.preventDefault();
    setIsCandidateDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(
      droppedFiles,
      allowedTypes,
      setCandidateFiles,
      candidateFiles
    );
  };

  const handleAddMoreCandidateFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(
      selectedFiles,
      allowedTypes,
      setAddMoreCandidateFiles,
      addMoreCandidateFiles
    );
  };

  const handleAddMoreCandidateDeleteFile = (index) => {
    setAddMoreCandidateFiles((prevFiles) =>
      prevFiles.filter((_, i) => i !== index)
    );
  };

  const handleAddMoreCandidateDragOver = (event) => {
    event.preventDefault();
    setIsAddMoreCandidateDragging(true);
  };

  const handleAddMoreCandidateDragLeave = () => {
    setIsAddMoreCandidateDragging(false);
  };

  const handleAddMoreCandidateDrop = (event) => {
    event.preventDefault();
    setIsLoading(false);
    setIsAddMoreCandidateDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(
      droppedFiles,
      allowedTypes,
      setAddMoreCandidateFiles,
      addMoreCandidateFiles
    );
  };

  const handleQmShare = () => {
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  const [tabData, setTabData] = useState([
    {
      name: "Upload Job Information",
      disabled: false,
    },
    {
      name: "Upload Candidates",
      disabled: true,
    },
    {
      name: "Review Match Information",
      disabled: false,
    },
  ]);

  const handleNextUploadClick = async () => {
    if (files.length == 0) {
      setSnackbarState((prev) => ({
        ...prev,
        open: true,
        message: "Upload the Files",
        severity: "error",
      }));
    } else {
      uploadFile("NextUpload");
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleJobTitle = (e) => {
    setJobTitle(e.target.value);
  };

  const handleGenerateUploadClick = async () => {
    if (candidateFiles.length == 0) {
      setSnackbarState({
        open: true,
        message: "Upload the Files",
        severity: "error",
      });
    } else {
      setUploadJobDataList([]);
      uploadFile("Upload-Candidate", setCandidateFiles);
    }
  };

  const headerData = ["Candidate", "Score", ""];
  const handleAddMoreCandidateClick = () => {
    if (addMoreCandidateFiles.length == 0) {
      setSnackbarState({
        open: true,
        message: "Upload the Files",
        severity: "error",
      });
    } else {
      setUploadJobDataList([]);
      uploadFile(
        "Upload-Candidate",
        setAddMoreCandidateFiles,
        "Add-more-Candidate"
      );
    }
  };

  const handleAddMoreCandidate = () => {
    setAddMoreCandidateDialogOpen(true);
    setAddMoreCandidateFiles([]);
  };

  const handleAddMoreCandidateDialogClose = () => {
    setAddMoreCandidateDialogOpen(false);
    setAddMoreCandidateFiles([]);
  };

  const handleWrongClose = () => {
    setWrongClose(false);
    if (
      value === 1 &&
      Array.isArray(uploadJobDataList) &&
      uploadJobDataList.length !== 0
    ) {
      setValue(2);
    }
  };

  const handleViewJob = () => {
    navigate("/jobs");
  };

  const handleResumeDelete = (row) => {
    setDeleteResume(true);
    setResumeRow(row);
  };

  const handleDeleteDialogClose = () => {
    setDeleteResume(false);
  };

  const handleDelete = async () => {
    setDeleteButtonLoading(true);
    try {
      const data = await JobJobDelete(resumeRow.id);
      setDeleteButtonLoading(false);
      handleDeleteDialogClose();
      handleReviewMatchInformation();
      setSnackbarState({
        open: true,
        message: data?.message,
        severity: "success",
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
          {"Quick Match"}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Box width={"100%"}>
          <TabContext value={value}>
            <Grid2 alignItems="center" marginTop={3}>
              <CreateTabList onChange={handleChange} aria-label="create-job">
                {tabData.map((item, index) => (
                  <Tab
                    label={item.name}
                    value={index}
                    disabled={item.disabled}
                    fontSize={"20px"}
                  />
                ))}
              </CreateTabList>
            </Grid2>
            <TabPanelView value={0} padding="1.5rem 0px 0px">
              <UploadJobInformation
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleFileUpload={handleFileUpload}
                isDragging={isDragging}
                files={files}
                handleDeleteFile={handleDeleteFile}
                handleNextUploadClick={handleNextUploadClick}
                handleJobTitle={handleJobTitle}
                jobTitle={jobTitle}
                isLoading={isLoading}
                buttonDisabled={buttonDisabled}
                tabData={tabData}
              />
            </TabPanelView>
            <TabPanelView value={1} padding="1.5rem 0px 0px">
              <UploadCandidate
                handleCandidateDragOver={handleCandidateDragOver}
                handleCandidateDragLeave={handleCandidateDragLeave}
                handleCandidateDrop={handleCandidateDrop}
                handleCandidateFileUpload={handleCandidateFileUpload}
                candidateFiles={candidateFiles}
                isCandidateDragging={isCandidateDragging}
                handleCandidateDeleteFile={handleCandidateDeleteFile}
                isLoading={isLoading}
                buttonDisabled={buttonDisabled}
                handleGenerateUploadClick={handleGenerateUploadClick}
                uploadJobData={uploadJobData}
                tabData={tabData}
              />
            </TabPanelView>
            <TabPanelView value={2} padding="1.5rem 0px 0px">
              <ReviewMatch
                handleAddMoreCandidateDragOver={handleAddMoreCandidateDragOver}
                handleAddMoreCandidateDragLeave={
                  handleAddMoreCandidateDragLeave
                }
                handleAddMoreCandidateDrop={handleAddMoreCandidateDrop}
                handleAddMoreCandidateFileUpload={
                  handleAddMoreCandidateFileUpload
                }
                addMoreCandidateFiles={addMoreCandidateFiles}
                isAddMoreCandidateDragging={isAddMoreCandidateDragging}
                handleAddMoreCandidateDeleteFile={
                  handleAddMoreCandidateDeleteFile
                }
                handleQmShare={handleQmShare}
                shareDialogOpen={shareDialogOpen}
                handleShareDialogClose={handleShareDialogClose}
                tableLoader={tableLoader}
                reviewMatchData={reviewMatchData}
                handleAddMoreCandidateClick={handleAddMoreCandidateClick}
                headerData={headerData}
                addMoreCandidateDialogOpen={addMoreCandidateDialogOpen}
                handleAddMoreCandidate={handleAddMoreCandidate}
                handleAddMoreCandidateDialogClose={
                  handleAddMoreCandidateDialogClose
                }
                isLoading={isLoading}
                buttonDisabled={buttonDisabled}
                handleViewJob={handleViewJob}
                handleResumeDelete={handleResumeDelete}
              />
            </TabPanelView>
          </TabContext>
        </Box>
      </Grid2>

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <SkillDeleteDialog
        deleteDialog={deleteResume}
        handleDeleteDialogClose={handleDeleteDialogClose}
        handleDeleteSkill={handleDelete}
        buttonLoading={deleteButtonLoading}
        title="Delete Candidate"
        content={resumeRow.candidate_name}
      />
      <ErrorDialog
        wrongClose={wrongClose}
        uploadJobDataList={uploadJobDataList}
        handleWrongClose={handleWrongClose}
      />
    </Grid2>
  );
}

export default QuickMatch;
