import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";
import FileUpload from "../../../portal/quick-match/components/FileUpload";

function UploadCandidateResume({
  handleDragOver,
  handleDragLeave,
  handleDrop,
  handleFileUpload,
  isDragging,
  files,
  handleDeleteFile,
  handleNextUploadClick,
  isLoading,
}) {
  return (
    <Box width={"600px"} margin={"auto"}>
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Typography fontWeight={600} fontSize={20}>
            {"Upload Resume"}
          </Typography>
        </Grid2>

        <Grid2 size={12}>
          <Typography
            fontSize={"14px"}
            fontWeight={500}
            marginBottom={2}
            color="#344054"
          >
            {"Resume"}
          </Typography>
          <FileUpload
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onChange={handleFileUpload}
            isDragging={isDragging}
            files={files}
            handleDeleteFile={handleDeleteFile}
            disabled={files.length !== 0}
          />
          <CommonButton
            width={"100%"}
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Generate Candidate Profile"
            padding={"10px 0px"}
            onClick={handleNextUploadClick}
            disabled={isLoading}
            loading={isLoading}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default UploadCandidateResume;
