import { URL } from "../../api/Api";
import { RESUMEINTELLIGENCE } from "../Action";
import { catchError, GET, POST } from "../axios/Axios";

export const ResumeIntelligenceUpload = async (formData) => {
  try {
    const response = await fetch(`${URL}/resume/parse/`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export const ResumeIntelligenceUploadResume = (data) => {
  return {
    type: RESUMEINTELLIGENCE.resumeIntelligenceUpload,
    data: data,
  };
};

export const ResumeIntelligenceSave = (params) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/resume/save/`, {}, params);
      dispatch({
        type: RESUMEINTELLIGENCE.resumeIntelligenceSave,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const ResumeIntelligenceFix = async (id) => {
  try {
    const response = await GET(`${URL}/resume/error/fix/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
